import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import { Col, OverlayTrigger, Tooltip, Collapse, Button, Form, Modal, Badge, Popover } from 'react-bootstrap'
import { MdLiveTv } from 'react-icons/md'
import { Link } from 'react-router-dom'
import ShareOffcanvasNew from '../../components/ShareOffcanvasNew'
import like_btn from '../../assets/images/icon/like_btn.png'
import love_btn from '../../assets/images/icon/love_btn.png'
import haha_btn from '../../assets/images/icon/haha_btn.png'
import wow_btn from '../../assets/images/icon/wow_btn.png'
import angry_btn from '../../assets/images/icon/angry_btn.png'
import sad_btn from '../../assets/images/icon/sad_btn.png'
import './style.css'
import { useUserAbout } from '../../context/UserAboutContext';
import api from '../../services/api';
import Cookies from 'js-cookie'
import { toast } from 'react-toastify'
import loader from '../../assets/images/page-img/page-load-loader.gif'
import Comment from '../../components/Comment'
import { useSelector } from 'react-redux'
import { formatDistanceToNow, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import placeholderIMG from '../../assets/images/placeholder.png'
import ShowPostModal from '../Post/modalShowPost'
import ShowReactionModal from '../../components/Reactions/modalShowReactions';
import { debounce } from 'lodash'
import { useInView } from 'react-intersection-observer'
import { FaSearch } from 'react-icons/fa'
import LiveChat from '../../components/LiveChat'

const Index = ({ user_name, user_image, live_name, live_iframe, post_time, post_id, privacy, user_reaction, reactions, event_id = null, is_live = false }) => {
  const [open3, setOpen3] = useState(false)
  const [openLiveChat, setOpenLiveChat] = useState(false)
  const [modalShow3, setModalShow3] = useState(false)
  const [showScheduleModal, setShowScheduleModal] = useState(false)
  const [reactionsModalShow, setReactionsModalShow] = useState(false)
  const logged_user = Cookies.get('userId')
  const handleComments = useSelector((state) => state.setting.setting.handle_comments)
  const [comments, setComments] = useState(null)
  const [newComment, setNewComment] = useState('')
  const [newReaction, setNewReaction] = useState(user_reaction)
  const [newReactionsCounter, setNewReactionsCounter] = useState(reactions)
  const { userAbout, fetchUserAbout } = useUserAbout()
  const [isLoading, setIsLoading] = useState(true)
  const [modalShowPost, setModalShowPost] = useState(false)
  const image_profile = userAbout?.user?.image_profile
  const vimeoIframeRef = useRef(null)
  const [isFullscreen, setIsFullscreen] = useState(false)
  const requestIntervalRef = useRef(null)
  const [schedules, setSchedules] = useState([])
  const [event, setEvent] = useState(null)

  const getSchedule = async () => {
    try {
      const response = await api.get(`/api/eventSchedules/${event_id}`)
      setSchedules(response.data.schedule)
      setEvent(response.data.event)
    } catch (error) {
      console.error('Erro')
      toast.error('Erro ao carregar programaação do evento.', { autoClose: 1000 })
    }
  }

  const handleDateField = (date) => {
    if (date && typeof date === 'string' && date.trim() !== '') {
      const [year, month, day] = date.split('-');
      const localDate = new Date(year, month - 1, day);
      return localDate.toLocaleDateString();
    }
    return '';
  };

  const formatScheduleField = (dateString, timeString) => {
    const formattedDate = handleDateField(dateString);
    const [hours, minutes] = timeString.split(':');
    const formattedTime = `${hours}:${minutes}`;

    return (
      <>
        <div className='d-flex align-items-center gap-1'>
          <span className="material-symbols-outlined fs-5">calendar_today</span> {formattedDate}
        </div>
        <div className='d-flex align-items-center gap-1'>
          <span className="material-symbols-outlined fs-5">schedule</span> {formattedTime}
        </div>
      </>
    );
  };

  useEffect(() => {
    fetchUserAbout()
  }, []);

  useEffect(() => {
    if (event_id) {
      getSchedule()
    }
  }, []);

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(document.fullscreenElement === vimeoIframeRef?.current?.querySelector('div')?.querySelector('iframe'));
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  useEffect(() => {

    const sendRequest = async () => {
      await api.post(`/api/streaming/storeViewLog`, {
        post_id
      }).catch(err => console.error(err))
    };

    if (isFullscreen) {
      requestIntervalRef.current = setInterval(sendRequest, 60000);
    } else {
      clearInterval(requestIntervalRef.current);
    }

    return () => clearInterval(requestIntervalRef.current);
  }, [isFullscreen]);

  useEffect(() => {
    let intervalo

    if (modalShowPost) {
      intervalo = setInterval(async () => {
        await api.post(`/api/streaming/storeViewLog`, {
          post_id
        }).catch(err => console.error(err))

      }, 60000)
    }

    return () => clearInterval(intervalo)
  }, [modalShowPost])

  const getComments = async () => {
    const response = await api.post(`/api/comments/getComments/${post_id}`)
    setIsLoading(false)
    setComments(response.data)
  }

  const commentStore = async (e) => {
    e.preventDefault()
    await api.post(`/api/comments/store/${post_id}`, {
      newComment,
      logged_user,
    }).then(() => {
      toast.success('Comentario feito com sucesso.', { autoClose: 1000 })
      setNewComment('')
      getComments()
    }).catch(err => toast.error('Erro ao criar comentario', { autoClose: 3000 }))
  }

  const sendReaction = async (reaction) => {
    await api.post(`/api/reactionPost/store`, {
      post_id,
      reaction
    }).catch(err => toast.error('Erro ao reagir post', { autoClose: 3000 }))
  }

  const removeReaction = async () => {
    setNewReactionsCounter(prev => prev - 1)
    setNewReaction('')
    await api.delete(`/api/reactionPost/destroy/${post_id}`).catch(err => toast.error('Erro ao deletar reação', { autoClose: 3000 }))
  }

  const handleReaction = (reaction) => {
    if (!newReaction) {
      setNewReactionsCounter(prev => prev + 1)
    }

    setNewReaction(reaction)
    sendReaction(reaction)
  }

  useEffect(() => {
    if (post_id === handleComments) {
      getComments()
    }
  }, [handleComments])

  const openModalReaction = () => {
    setReactionsModalShow(true);
  };

  const [viewers, setViewers] = useState([]);
  const [viewersModalShow, setViewersModalShow] = useState(false);
  const [viewCount, setViewCount] = useState(0);
  const [limitedViewers, setLimitedViewers] = useState([]);
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const { ref, inView } = useInView({
    threshold: 0.1,
  })

  const fetchViewers = useCallback(async (resetPage = false) => {
    setIsLoading(true);
    const currentPage = resetPage ? 1 : page;
    try {
      const response = await api.post(`/api/posts/${post_id}/viewers`, {
        search,
        page: currentPage,
        limit: 10
      });

      const data = response.data.data;
      let newViewers;

      if (resetPage) {
        newViewers = data;
        setViewers(data);
        setPage(2);
      } else {
        newViewers = [...viewers, ...data];
        setViewers(newViewers);
        setPage(currentPage + 1);
      }

      setLimitedViewers(newViewers.slice(0, 10));
      setHasMore(data.length === 10);
    } catch (error) {
      console.error('Failed to fetch user data:', error);
    } finally {
      setIsLoading(false);
    }
  }, [search, page, post_id, viewers]);

  const handleSearchChange = useCallback(
    debounce((newSearch) => {
      setSearch(newSearch);
      fetchViewers(true);
    }, 300),
    []
  );

  useEffect(() => {
    fetchViewers(true);
  }, [search]);

  useEffect(() => {
    if (inView && hasMore && !isLoading) {
      fetchViewers(false);
    }
  }, [inView, hasMore, isLoading]);

  const filteredViewers = useMemo(() => {
    return search
      ? viewers.filter(f => f.name.toLowerCase().includes(search.toLowerCase()))
      : viewers;
  }, [search, viewers]);

  const fetchViewCount = useCallback(async () => {
    try {
      const response = await api.get(`/api/posts/${post_id}/view-count`);
      setViewCount(response.data.view_count);
    } catch (error) {
      console.error('Erro ao buscar contagem de visualizações:', error);
    }
  }, [post_id]);

  useEffect(() => {
    fetchViewCount();
    const interval = setInterval(fetchViewCount, 300000);
    return () => clearInterval(interval);
  }, [fetchViewCount]);

  const handleModalShow = () => {
    setViewersModalShow(true);
  };

  const remainingViewers = Math.max(0, viewCount - 10);

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        {limitedViewers.length > 0 ? (
          <>
            {limitedViewers.map((viewer) => (
              <div key={viewer.id}>{viewer.name}</div>
            ))}
            {remainingViewers > 0 && (
              <Badge pill>
                ...e mais {remainingViewers} usuários
              </Badge>
            )}
          </>
        ) : (
          <div>Nenhum visualizador ainda</div>
        )}
      </Popover.Body>
    </Popover>
  );

  return (
    <Col sm={12} className='special-post'>

      <ShowReactionModal
        post_id={post_id}
        showModalReaction={reactionsModalShow}
        setShowModalReaction={setReactionsModalShow}
        onHide={() => setReactionsModalShow(false)}
      />

      <ShowPostModal
        showModalPost={modalShowPost}
        setModalShowPost={setModalShowPost}
        onHide={() => setModalShowPost(false)}
        post_id={post_id}
        user_image={user_image}
        user_name={user_name}
        user_reaction={user_reaction}
        reactions={reactions}
        post_time={post_time}
        newText={live_name}
        newType='live'
        newMedia={live_iframe}
        scheduleShow={schedules.length > 0 ? setShowScheduleModal : false}
        event={event}
        is_live={is_live}
      />

      <Modal show={showScheduleModal} onHide={() => setShowScheduleModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Programação do evento</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {schedules.length > 0 && (
            <div className="mt-3">
              <ul>
                {schedules.map((prog, index) => (
                  <div className="mt-3" key={index}>
                    <Badge bg="secondary" className="fs-6">{prog.name}</Badge>
                    <div className="ms-3 mt-2 d-flex gap-4">
                      <div>
                        <span className="fw-bold">Início: </span>
                        {formatScheduleField(prog.data_inicio.split(' ')[0], prog.data_inicio.split(' ')[1])}
                      </div>
                      {prog.data_fim && (
                        <div>
                          <span className="fw-bold">Término: </span>
                          {formatScheduleField(prog.data_fim.split(' ')[0], prog.data_fim.split(' ')[1])}
                        </div>
                      )}
                    </div>
                    <div className="ms-3 mt-1">Descrição:<span className="fw-bold ms-1">{prog.descricao}</span></div>
                  </div>
                ))}
              </ul>
            </div>
          )}
        </Modal.Body>
      </Modal>

      <div className='card card-block card-stretch card-height'>
        <div className='card-body'>
          <div className='user-post-data'>
            <div className='d-flex justify-content-between align-items-center'>
              <div className='me-3 flex-shrik-0'>
                <img
                  className='border border-2 rounded-circle user-post-profile'
                  src={user_image}
                  alt=''
                />
              </div>
              <div className='w-100'>
                <div className='d-flex justify-content-between align-items-center'>
                  <div>
                    <div className='d-flex' style={{ alignItems: 'center' }}  >
                      <h6 className='mb-0 d-inline-block'>{user_name} {' '} </h6>
                      <span className='d-inline-block text-primary'>
                        <MdLiveTv className='d-flex mx-2' style={{ fontSize: '1.5rem' }} />
                      </span>{' '}
                      <p className='mb-0 d-inline-block text-capitalize fw-medium'>
                        {live_name}
                      </p>
                    </div>
                    <p className='mb-0'>{formatDistanceToNow(parseISO(post_time), { addSuffix: true, locale: ptBR })}</p>
                  </div>
                </div>
              </div>

              <Button onClick={() => setModalShowPost(true)} type="button" variant="primary">Assistir</Button>
            </div>
          </div>
          <div className='user-post mt-4 streaming-container'>
            <div className='ratio ratio-16x9' style={{ height: '100%' }}>
              <div ref={vimeoIframeRef} dangerouslySetInnerHTML={{ __html: live_iframe }} style={{ height: '100%' }} />
            </div>
          </div>

          <div className='post-meta-likes mt-4'>
            <div className='d-flex align-items-center gap-2 flex-wrap'>
              <div className='d-inline-flex w-100 justify-content-between'>
                <span
                  className='text-capitalize font-size-14 fw-medium'
                  type='button'
                  data-bs-toggle='modal'
                  data-bs-target='#likemodal'
                >
                  {
                    newReactionsCounter > 0 ? (
                      <div>
                        <img src={like_btn} className='img-fluid me-2' alt='' style={{ 'height': '35px', 'width': '35px' }} onClick={openModalReaction} /> {newReactionsCounter}
                      </div>
                    ) : ''
                  }
                </span>
                <OverlayTrigger
                  trigger={['hover', 'focus']}
                  placement="top"
                  overlay={popover}
                  onEnter={() => fetchViewers(true)}
                >
                  <Button variant="link" className="p-0 m-0 fw-medium" onClick={handleModalShow}>
                    {viewCount} {viewCount === 1 ? 'visualização' : 'visualizações'}
                  </Button>
                </OverlayTrigger>
              </div>
            </div>
          </div>

          <Modal show={viewersModalShow} onHide={() => setViewersModalShow(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Usuários que visualizaram</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group className='input-group pb-3'>
                <span className='input-group-text' id='basic-addon1'><FaSearch /></span>
                <Form.Control
                  placeholder='Pesquisar'
                  aria-label='Pesquisar'
                  aria-describedby='search'
                  onChange={(e) => handleSearchChange(e.target.value)}
                />
              </Form.Group>
              <div className="item-header-scroll">
                {filteredViewers.map((viewer, index) => (
                  <Col md={6} key={index} ref={index === filteredViewers.length - 1 ? ref : null}>
                    <div key={`${viewer.id}-${index}`} className="d-flex align-items-center gap-3 mb-3">
                      <a href={`/users/profile/${viewer.id}`} className="d-flex align-items-center">
                        <img
                          className="avatar-32 rounded-pill"
                          src={viewer?.image_profile}
                          alt={viewer.name}
                        />
                      </a>
                      <a href={`/users/profile/${viewer.id}`} className="text-decoration-none">
                        <span>{viewer.name}</span>
                      </a>
                    </div>
                  </Col>
                ))}
              </div>
              {isLoading && <div className="col-sm-12 text-center">
                <img src={loader} alt="loader" style={{ height: "50px" }} />
              </div>}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setViewersModalShow(false)}>
                Fechar
              </Button>
            </Modal.Footer>
          </Modal>

          <div className='comment-area mt-4 pt-4 border-top'>
            <div className='d-flex justify-content-between align-items-center flex-wrap'>
              <div className='like-block position-relative d-flex align-items-center flex-shrink-0'>
                <div className='like-data'>
                  <div className='dropdown'>
                    <span
                      className='dropdown-toggle'
                      data-bs-toggle='dropdown'
                      aria-haspopup='true'
                      aria-expanded='false'
                      role='button'
                    >
                      {
                        !newReaction ? (
                          <span className='material-symbols-outlined align-text-top font-size-20'>
                            thumb_up
                          </span>
                        ) : (
                          <img src={newReaction === 'like' ? like_btn : newReaction === 'love' ? love_btn : newReaction === 'haha' ? haha_btn : newReaction === 'wow' ? wow_btn : newReaction === 'sad' ? sad_btn : newReaction === 'angry' ? angry_btn : ''} className='img-fluid me-2' alt='' onClick={() => removeReaction()} style={{ 'height': '35px', 'width': '35px' }} />
                        )
                      }

                      {' '}
                      <span className='fw-medium'>{
                        newReaction === 'like' ? 'Gostei' : newReaction === 'love' ? 'Amei' : newReaction === 'haha' ? 'Haha' : newReaction === 'wow' ? 'Uau' : newReaction === 'sad' ? 'Triste' : newReaction === 'angry' ? 'Grr' : 'Reagir'
                      }</span>
                    </span>
                    <div className='dropdown-menu py-2 shadow'>
                      <OverlayTrigger
                        placement='top'
                        overlay={<Tooltip>Gostei</Tooltip>}
                        className='ms-2 me-2'
                      >
                        <img src={like_btn} className='img-fluid me-2' alt='' onClick={() => handleReaction('like')} style={{ 'height': '35px', 'width': '35px' }} />
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement='top'
                        overlay={<Tooltip>Amei</Tooltip>}
                        className='me-2'
                      >
                        <img src={love_btn} className='img-fluid me-2' alt='' onClick={() => handleReaction('love')} style={{ 'height': '35px', 'width': '35px' }} />
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement='top'
                        overlay={<Tooltip>HaHa</Tooltip>}
                        className='me-2'
                      >
                        <img src={haha_btn} className='img-fluid me-2' alt='' onClick={() => handleReaction('haha')} style={{ 'height': '35px', 'width': '35px' }} />
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement='top'
                        overlay={<Tooltip>Uau</Tooltip>}
                        className='me-2'
                      >
                        <img src={wow_btn} className='img-fluid me-2' alt='' onClick={() => handleReaction('wow')} style={{ 'height': '35px', 'width': '35px' }} />
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement='top'
                        overlay={<Tooltip>Triste</Tooltip>}
                        className='me-2'
                      >
                        <img src={sad_btn} className='img-fluid me-2' alt='' onClick={() => handleReaction('sad')} style={{ 'height': '35px', 'width': '35px' }} />
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement='top'
                        overlay={<Tooltip>Grr</Tooltip>}
                        className='me-2'
                      >
                        <img src={angry_btn} className='img-fluid me-2' alt='' onClick={() => handleReaction('angry')} style={{ 'height': '35px', 'width': '35px' }} />
                      </OverlayTrigger>
                    </div>
                  </div>
                </div>
              </div>
              <div className='share-block d-flex align-items-center feather-icon gap-3 flex-shrink-0'>
                {
                  !is_live ? (
                    <Link
                      className='total-comment-block'
                      type='button'
                      aria-controls='commentcollapes'
                      aria-expanded={open3}
                      onClick={() => { setOpen3(!open3); getComments(); }}
                    >
                      <span className='material-symbols-outlined align-text-top font-size-20'>
                        comment
                      </span>{' '}
                      <span className='fw-medium'> Comentários</span>
                    </Link>
                  ) : ''
                }

                <Link
                  className='total-comment-block'
                  type='button'
                  aria-controls='messageCollapse'
                  aria-expanded={openLiveChat}
                  onClick={() => { setOpenLiveChat(!openLiveChat) }}
                >
                  <span className='material-symbols-outlined align-text-top font-size-20'>
                    chat
                  </span>{' '}
                  <span className='fw-medium'> Live chat</span>
                </Link>

                <div className='share-block d-flex align-items-center feather-icon'>
                  <Link
                    to='#'
                    data-bs-toggle='modal'
                    data-bs-target='#share-btn'
                    onClick={() => setModalShow3(true)}
                    aria-controls='share-btn'
                    className='d-flex align-items-center'
                  >
                    <span className='material-symbols-outlined align-text-top font-size-20'>
                      share
                    </span>
                    <span className='ms-1 fw-medium'>Compartilhar</span>
                  </Link>
                </div>
                <ShareOffcanvasNew user_name={user_name} user_image={user_image} live_name={live_name} live_iframe={live_iframe} post_time={post_time} post_id={post_id} privacy={privacy}
                  show={modalShow3}
                  onHide={() => setModalShow3(false)}
                />
              </div>
            </div>

            <Collapse in={open3}>
              <div id='commentcollapes' className='border-top mt-4 pt-4'>
                {isLoading && <div className='col-sm-12 text-center'>
                  <img src={loader} alt='loader' style={{ height: '100px' }} />
                </div>
                }

                {comments && comments.map((comment, index) => (
                  <Comment comment={comment} key={`post_comment_${comment.id}`} />
                ))}

                <div className='add-comment-form-block'>
                  <div className='d-flex align-items-center gap-3'>
                    <div className='flex-shrink-0'>
                      <img
                        src={image_profile ? image_profile : placeholderIMG}
                        alt='userimg'
                        className='avatar-48 rounded-circle img-fluid'
                        loading='lazy'
                      />
                    </div>
                    <div className='add-comment-form'>
                      <Form onSubmit={commentStore}>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Escrever comentário...'
                          value={newComment} onChange={(e) => setNewComment(e.target.value)}
                        />
                        <Button type="submit" variant="primary">Comentar</Button>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </Collapse>

            <Collapse in={openLiveChat}>
              <div id='messageCollapse'>
                <LiveChat post_id={post_id} is_live={is_live} />
              </div>
            </Collapse>

          </div>
        </div>
      </div>
    </Col>
  )
}

export default Index
