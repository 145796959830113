import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Form, Container, Button, Card, Table } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import api from "../../services/api";
import { toast } from "react-toastify";
import Cookies from 'js-cookie';

const SorteioCreate = ({ onSorteioCreated }) => {
    const userId = Cookies.get('userId');
    const [validated, setValidated] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [sendInvites, setSendInvites] = useState(false);
    const [sendGroupInvites, setSendGroupInvites] = useState(false);
    const history = useNavigate();
    const [title, setTitle] = useState('');
    const [descricao, setDesc] = useState('');
    const [winners, setNumberWinners] = useState(1);
    const [groups, setGroups] = useState([])
    const [selectedGroup, setSelectedGroup] = useState('')
    const [autoRegister, setAutoRegister] = useState(false);

    const sendInvitations = async (sorteio_id) => {
        try {
            const response = await api.post(`api/sorteio/${sorteio_id}/invite`);
            toast.success("Convites enviados com sucesso!");
            setSendInvites(true)

        } catch (error) {
            console.error("Erro ao enviar convites:", error);
            toast.error("Erro ao enviar convites.");
        }
    };

    const handleAutoRegister = async (sorteio_id) => {
        try {
            await api.post(`/api/sorteios/${sorteio_id}/auto-register`);
            toast.success('Todos os usuários foram inscritos automaticamente.');
            setAutoRegister(true);
            setSendInvites(3);
        } catch (error) {
            console.error('Erro ao inscrever usuários automaticamente:', error);
            toast.error('Erro ao inscrever usuários automaticamente.');
        }
    };

    const sendGroupInvitations = async (sorteio_id, group_id) => {
        try {
            const response = await api.post(`api/sorteio/${sorteio_id}/invitegp/${group_id}`);
            toast.success("Grupo convidado com sucesso!");
            setSendGroupInvites(true)
        } catch (error) {
            console.error("Erro ao convidar grupo:", error);
            toast.error("Erro ao convidar grupo.");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setShowSpinner(true);

        if (title === '') {
            setShowSpinner(false)
            toast.error('Por favor preencha o titulo.', { autoClose: 3000 })
        } else if (winners <= 0) {
            setShowSpinner(false)
            toast.error('No minimo um ganhador por sorteio.', { autoClose: 3000 })
        } else {

            setValidated(true)

            const sorteioData = {
                title: title,
                descricao: descricao,
                winners: winners,
                active: false,
                convite_user: sendInvites,
                group_id: selectedGroup
            };

            try {
                console.log(sorteioData)
                const response = await api.post('api/sorteio/create', sorteioData);
                if (response.data.error) {
                    toast.error(response.data.error);
                } else {
                    toast.success("Sorteio criado com sucesso!", {
                        autoClose: 300,
                        onClose: () => history('/sorteio/index'),
                    });

                    if (sendInvites) {
                        await sendInvitations(response.data.sorteio.id);
                    }

                    if (autoRegister) {
                        handleAutoRegister(response.data.sorteio.id);
                    }

                    if (selectedGroup != '') {
                        await sendGroupInvitations(response.data.sorteio.id, selectedGroup);
                    }

                    if (onSorteioCreated) {
                        onSorteioCreated();
                    }
                }
            } catch (error) {
                console.error('Erro ao criar o sorteio:', error);
                toast.error('Ocorreu um erro ao criar o sorteio.');
            } finally {
                setShowSpinner(false);
            }
        }
    };

    useEffect(() => {
        let cancel = false

        async function fetchData() {
            try {
                const response = await api.get(`/api/groupsByUser/${userId}`)

                if (!cancel) {
                    const data = response.data
                    setGroups(data.groups)
                }
            } catch (error) {
                console.error('Failed to fetch user groups:', error)
                toast.error('Erro ao recuperar grupos', { autoClose: 3000 })
            }
        }

        fetchData()

        return () => {
            cancel = true
        }
    }, [])

    return (
        <div id='content-page' className='content-inner'>
            <Container>
                <Row>
                    <Col sm='12'>
                        <Card className='position-relative inner-page-bg bg-primary p-5'>
                            <div className='inner-page-title' style={{ display: 'flex', justifyContent: 'space-between', width: '-webkit-fill-available' }}>
                                <h3 className='text-white'>Sorteios</h3>
                                <Link to='/sorteio/index'>
                                    <Button><i className='icon material-symbols-outlined d-flex'>arrow_back</i></Button>
                                </Link>
                            </div>
                        </Card>
                    </Col>
                    <Col sm='12'>
                        <Card>
                            <Card.Header>
                                <h4 className='card-title'>Criar Sorteio</h4>
                            </Card.Header>
                            <Card.Body>
                                <Form onSubmit={handleSubmit} validated={validated}>
                                    <Form.Group controlId="formTitle" className="mb-1">
                                        <div className="d-flex flex-wrap gap-2">
                                            <div className="w-50">
                                                <Form.Label>Titulo</Form.Label>
                                                <Form.Control
                                                    className="my-2"
                                                    type="text"
                                                    placeholder="Titulo"
                                                    value={title}
                                                    onChange={(e) => setTitle(e.target.value)}
                                                    required
                                                />
                                            </div>
                                            <div className="">
                                                <Form.Label>Nº de Ganhadores</Form.Label>
                                                <Form.Control
                                                    className="my-2 w-50"
                                                    type="number"
                                                    value={winners}
                                                    onChange={(e) => setNumberWinners(e.target.value)}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </Form.Group>

                                    <Form.Group className="mb-3">

                                        <Form.Label>Descrição</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            placeholder="Descrição do sorteio"
                                            value={descricao}
                                            onChange={(e) => setDesc(e.target.value)}

                                        />

                                    </Form.Group>

                                    <Form.Group controlId="formSendInvites" className="mb-3">
                                        <Form.Check
                                            type="switch"
                                            label="Convidar todos os usuários"
                                            checked={sendInvites}
                                            onChange={(e) => setSendInvites(e.target.checked)}
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="formAutoRegister" className="mb-3">
                                        <Form.Check
                                            id="formAutoRegister"
                                            type="switch"
                                            label="Inscrever todos os usuários automaticamente"
                                            checked={autoRegister}
                                            onChange={(e) => setAutoRegister(e.target.checked)}
                                        />
                                    </Form.Group>


                                    <div className="w-50">
                                        <label htmlFor='groups' className='form-label'>Convidar grupo</label>
                                        <select
                                            id='groups'
                                            value={selectedGroup}
                                            onChange={(e) => setSelectedGroup(e.target.value)}
                                            className='form-control my-2'
                                        >
                                            {
                                                groups.map((group, index) => (
                                                    <option value={group.value} key={`group_${index}`}>
                                                        {group.label}
                                                    </option>
                                                ))
                                            }
                                        </select>

                                    </div>

                                    <div className="d-flex justify-content-between mt-3">
                                        <Button disabled={showSpinner} variant="primary" type="submit">
                                            {showSpinner ? <div className="spinner-border spinner-border-sm" role="status"></div> : 'Criar'}
                                        </Button>
                                    </div>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default SorteioCreate;
