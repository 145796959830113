import React, { useEffect, useState, useMemo } from 'react'
import { Container, Row, Col, Card, Button, Modal, Form } from "react-bootstrap";
import { useNavigate } from 'react-router-dom'
import { Link } from "react-router-dom";
import api from '../../services/api'
import { toast } from 'react-toastify'
import AnimationWarningLottie from '../../components/AnimationWarningDeleteConfim/AnimationWarningLottie'
import { FaSearch } from 'react-icons/fa'

const UsersIndex = () => {
    const history = useNavigate()
    const [users, setUsers] = useState([])
    const [modalShow, setModalShow] = useState(false)
    const [modalShowInvite, setModalInviteShow] = useState(false)
    const [userIdToDelete, setUserIdToDelete] = useState('')
    const [userNameToDelete, setUserNameToDelete] = useState('')
    const [search, setSearch] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [usersPerPage] = useState(10)
    const indexOfLastUser = currentPage * usersPerPage
    const indexOfFirstUser = indexOfLastUser - usersPerPage
    const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser)
    const nPages = Math.ceil(users.length / usersPerPage)

    const Pagination = ({ nPages, currentPage, setCurrentPage }) => {
        if (nPages <= 1) return null;

        const range = (start, end) => {
            return Array(end - start + 1)
                .fill()
                .map((_, idx) => start + idx);
        };

        let pageNumbers = [];
        if (currentPage <= 3) {
            pageNumbers = [...range(1, Math.min(5, nPages))];
            if (nPages > 5) pageNumbers.push('...', nPages);
        } else if (currentPage > 3 && currentPage < nPages - 2) {
            pageNumbers = [1, '...', ...range(currentPage - 1, currentPage + 1), '...', nPages];
        } else {
            pageNumbers = [1, '...'];
            pageNumbers.push(...range(nPages - 4, nPages));
        }

        return (
            <nav className='d-flex justify-content-center align-items-center'>
                <ul className="pagination mb-4">
                    <li className="page-item"><Link className="page-link" onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}>
                        <i className="icon material-symbols-outlined d-flex">
                            arrow_back
                        </i></Link></li>
                    {pageNumbers.map((number, index) => (
                        <li key={index} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                            <Link className="page-link" onClick={() => typeof number === 'number' && setCurrentPage(number)}> {number}</Link></li>
                    ))}
                    <li className="page-item"><Link className="page-link" onClick={() => setCurrentPage(prev => Math.min(prev + 1, nPages))}>
                        <i className="icon material-symbols-outlined d-flex">
                            arrow_forward
                        </i></Link></li>
                </ul>
            </nav>
        );
    };

    const getUsers = async () => {

        try {
            const response = await api.post('/api/users/index')
            setUsers(response.data.users)

        } catch (error) {
            console.error('Erro')
            toast.error('Erro ao carregar dados.', { autoClose: 3000 });
        }

    }

    const showEditUserPage = (userId) => {
        history(`/users/edit/${userId}`)
    }

    const showCreateUserPage = () => {
        history(`/users/create`)
    }

    const showDeleteUserConfirmationModal = (userId, userName) => {
        setModalShow(true)
        setUserIdToDelete(userId)
        setUserNameToDelete(userName)
    }

    const showInviteUsersModal = (userId, userName) => {
        setModalInviteShow(true)
        setUserIdToDelete(userId)
        setUserNameToDelete(userName)
    }

    const deleteUser = async (userId) => {
        await api.delete(`/api/user/destroy/${userId}`).then(() => {
            getUsers()
            setModalShow(false)
            toast.success('Usuário deletado com sucesso!', { autoClose: 3000 })
        })
    }

    function DeleteUserConfirmationModal(props) {
        return (
            <Modal
                {...props}
                aria-labelledby='contained-modal-title-vcenter'
                centered
            >
                <Modal.Header id='modalHeader' closeButton>
                    <div id='divModalTitle'>
                        <Modal.Title id='modalTitle'>
                            Exclusão de Usuário
                        </Modal.Title>
                    </div>
                </Modal.Header>
                <Modal.Body id='modalBody'>
                    <div className="d-flex justify-content-center">
                        <AnimationWarningLottie />
                    </div>
                    <div className="d-flex justify-content-center">
                        <p>
                            Você tem certeza de que deseja remover o usuário "{props.username}" ?
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer id='modalFooter'>
                    <Button id='yesButton' onClick={() => deleteUser(props.userid)}>Sim</Button>
                    <Button id='noButton' onClick={props.onHide}>Não</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    function InviteUsersModal(props) {
        const [emailOptions, setEmailOptions] = useState([]);
        const [inputValue, setInputValue] = useState('');
        const [showSpinner, setShowSpinner] = useState('');

        const handleInvite = async () => {
            setShowSpinner(true);
            const emails = emailOptions.map(option => option.value);
            if (emails.length === 0) {
                toast.error('Por favor, adicione pelo menos um e-mail.');
                return;
            }
            try {
                await api.post('/api/users/invite', { emails });
                setEmailOptions([]);
                setInputValue('');
                toast.success('Convites enviados com sucesso!');
                setShowSpinner(false);
                props.onHide();
            } catch (error) {
                toast.error('Erro ao enviar convites.');
                console.error(error);
            }
        };


        const validateEmail = (email) => {
            const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            return emailPattern.test(email);
        };
    
        const handlePaste = (event) => {
            const paste = event.clipboardData.getData('text/plain');
            const emails = paste.split(/[\s,;]+/).filter(email => email.trim() && validateEmail(email.trim()));
            const newEmails = emails.filter(email => !emailOptions.find(option => option.value === email.trim()));
            
            if (newEmails.length > 0) {
                setEmailOptions(prevOptions => [
                    ...prevOptions,
                    ...newEmails.map(email => ({ value: email.trim(), label: email.trim() }))
                ]);
            }
            event.preventDefault();
        };
    
        const handleKeyDown = (event) => {
            if (event.key === 'Enter' && inputValue) {
                const newEmail = inputValue.trim();
                if (newEmail && validateEmail(newEmail) && !emailOptions.find(option => option.value === newEmail)) {
                    setEmailOptions(prevOptions => [
                        ...prevOptions,
                        { value: newEmail, label: newEmail }
                    ]);
                } else if (!validateEmail(newEmail)) {
                    toast.error('E-mail inválido!');
                }
                setInputValue('');
                event.preventDefault();
            }
        };
    
        const handleDelete = (emailToDelete) => {
            setEmailOptions(emailOptions.filter(option => option.value !== emailToDelete));
        };
    

        return (
            <Modal
                {...props}
                aria-labelledby='contained-modal-title-vcenter'
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Convidar Usuários</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={e => e.preventDefault()}>
                        <Form.Group className="mb-3">
                            <Form.Label>E-mails (adicione e-mails e aperte Enter)</Form.Label>
                            <input
                                type="text"
                                value={inputValue}
                                onChange={e => setInputValue(e.target.value)}
                                onKeyDown={handleKeyDown}
                                onPaste={handlePaste}
                                className="form-control"
                                placeholder="Digite ou cole os e-mails..."
                            />
                        </Form.Group>
                        <ul>
                        {emailOptions.map((email, index) => (
                            <li key={index}>
                                {email.label}
                                <button
                                className='btn btn' 
                                    variant="danger" 
                                    size="sm" 
                                    onClick={() => handleDelete(email.value)} 
                                >
                                    <i className="material-symbols-outlined d-flex" style={{ fontSize: "small", color: 'red' }}> close </i>
                                </button>
                            </li>
                        ))}
                    </ul>
                        <Button variant="primary" onClick={handleInvite} disabled={emailOptions.length === 0 || showSpinner}>
                            {showSpinner ? <div className="spinner-border spinner-border-sm" role="status"></div> : <div className='fw-semibold'>Enviar</div>}
                        </Button>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onHide}>Fechar</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    useEffect(() => {
        getUsers()
    }, [])

    const filteredUsers = useMemo(() => {
        const lowerSearch = search.toLowerCase()

        if (search === '') {
            return currentUsers
        } else {
            return users.filter((user) => user.name.toLowerCase().includes(lowerSearch) || user.email.toLowerCase().includes(lowerSearch) || user.id.toString().toLowerCase().includes(lowerSearch))
        }
    }, [search, users, currentUsers])

    return (
        <>
            <div id='content-page' className='content-inner'>
                <Container>
                    <DeleteUserConfirmationModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        userid={userIdToDelete}
                        username={userNameToDelete}
                    />
                    <InviteUsersModal
                        show={modalShowInvite}
                        onHide={() => setModalInviteShow(false)}
                        userid={userIdToDelete}
                        username={userNameToDelete}
                    />
                    <Row>
                        <Col sm="12">
                            <Card
                                className="position-relative inner-page-bg bg-primary p-5"

                            >
                                <div className="inner-page-title">
                                    <h3 className="text-white">Usuários</h3>
                                </div>
                            </Card>
                        </Col>
                        <Col sm="12">
                            <div className="mb-4">
                                <Button onClick={() => showInviteUsersModal()} id='buttonNew' className='d-flex'>
                                    Convidar Usuários
                                    <i className="icon material-symbols-outlined d-flex ms-1">
                                        3p
                                    </i>
                                </Button>
                            </div>
                        </Col>
                        <Col sm="12" lg="12">
                            <Card>
                                <Card.Body className="pt-0">
                                    <div className="mb-4 mt-4">
                                        <Form.Group className="input-group">
                                            <span className="input-group-text" id="basic-addon1">
                                                <FaSearch />
                                            </span>
                                            <Form.Control
                                                placeholder='Pesquisar'
                                                aria-label='pesquisar'
                                                aria-describedby='search'
                                                onChange={(e) => setSearch(e.target.value)}
                                            />
                                            <Button onClick={() => showCreateUserPage()} id='buttonNew' className='borderbtnCreateEdit'>
                                                <i className="icon material-symbols-outlined d-flex">
                                                    person_add
                                                </i>
                                            </Button>
                                        </Form.Group>
                                    </div>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Nome</th>
                                                <th scope="col">E-mail</th>
                                                <th scope="col">Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredUsers.map((user, index) => (
                                                <tr key={index}>
                                                    <th scope="row">{user.id}</th>
                                                    <td>{user.name}</td>
                                                    <td>{user.email}</td>
                                                    <td>
                                                        <Button style={{ marginRight: "5px" }} onClick={() => showEditUserPage(user.id)} >
                                                            <i onClick={() => showEditUserPage(user.id)} className="material-symbols-outlined d-flex"> mode_edit </i>
                                                        </Button>
                                                        <Button onClick={() => showDeleteUserConfirmationModal(user.id, user.name)}>
                                                            <i className="material-symbols-outlined d-flex"> delete </i>
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </Card.Body>
                            </Card>
                            <Pagination
                                nPages={nPages}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default UsersIndex