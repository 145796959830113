import React, { useEffect, useState, useRef } from 'react';
import { Modal, Button, Col, Form } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import api from '../services/api';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { useUserAbout } from '../context/UserAboutContext';
import Cropper from 'react-easy-crop';
import getCroppedImg from "../components/getCroppedImag/getCroppedImg";
import PlaceholderBackground from "../assets/images/logo-full.png";
import PlaceholderProfile from "../assets/images/generic_user.png";
import '../../src/views/users/style-user-profile-edit.css'

const UserFirstAccessCheck = () => {
    const [showEditModal, setShowEditModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const userId = Cookies.get('userId');
    const token = Cookies.get('token');
    const [validated, setValidated] = useState(false);
    const customId = 'success-toast-id';
    const { userAbout, fetchUserAbout } = useUserAbout();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [organization, setOrganization] = useState('');
    const [employmentStartDate, setEmploymentStartDate] = useState('');
    const [companyLocation, setCompanyLocation] = useState('');
    const [professionalEmail, setProfessionalEmail] = useState('');
    const [birthDate, setBirthDate] = useState('');
    const [interestedIn, setInterestedIn] = useState('');
    const [language, setLanguage] = useState('');
    const [password, setPassword] = useState('');
    const [confPassword, setConfPassword] = useState('');
    const [imageSrc, setImageSrc] = useState('');
    const [profileImage, setProfileImage] = useState('');
    const [backgroundImage, setBackgroundImage] = useState('');
    const [backgroundSrc, setBackgroundSrc] = useState('');
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState('');
    const [isCropping, setIsCropping] = useState(false);
    const [isCroppingBackground, setIsCroppingBackground] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false)
    const [positions, setPositions] = useState([]);
    const [positionId, setPositionId] = useState("");

    const fileInputRef = useRef(null);
    const fileBackgroundInputRef = useRef(null);
    const history = useNavigate();

    const checkFirstAccess = async () => {
        try {
            const response = await api.post(`/api/user/check-first-access/${userId}`);
            if (response.data.user.first_access === 1) {
                setShowEditModal(true);
            }
        } catch (error) {
            console.error('Failed to check first access:', error);
            toast.error('Erro ao verificar o acesso inicial.');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        let cancel = false;

        async function fetchData() {
            try {
                const response = await api.post(`/api/user/about/`, {
                    userId,
                    token,
                });

                if (!cancel) {
                    const data = response.data[0];

                    setName(data.user?.name ?? '');
                    setEmail(data.user?.email ?? '');
                    setPositionId(data.user?.position_id ?? '');
                    setOrganization(data.user?.organization ?? '');
                    setEmploymentStartDate(data.user?.employment_start_date ?? '');
                    setCompanyLocation(data.user?.company_location ?? '');
                    setProfessionalEmail(data.user?.professional_email ?? '');
                    setBirthDate(data.user?.birth_date ?? '');
                    setInterestedIn(data.user?.interested_in ?? '');
                    setLanguage(data.user?.language ?? '');
                    setImageSrc(data.user?.image_profile ?? '');
                    setBackgroundSrc(data.user?.background_image ?? '');
                }
            } catch (error) {
                console.error('Failed to fetch user data:', error);
            }
        }

        fetchData();

        return () => {
            cancel = true;
        };
    }, [userId, token]);

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleBackgroundButtonClick = () => {
        fileBackgroundInputRef.current.click();
    };


    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setProfileImage(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageSrc(reader.result);
                setIsCropping(true); // Habilita o modo de corte
            };
            reader.readAsDataURL(file);
        }
    };

    const handleFileBackgroundChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setBackgroundImage(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setBackgroundSrc(reader.result);  // Atualiza o preview do background
                setIsCroppingBackground(true); // Habilita o modo de corte
            };
            reader.readAsDataURL(file);
        }
    };

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    };
    //Antes de modificar foto de perfil
    const showCroppedImage = async (src, setImageFunction, setFileFunction) => {
        try {
            // Obtém o blob da imagem cortada
            const croppedImage = await getCroppedImg(src, croppedAreaPixels);
            const croppedImageFile = new File([croppedImage], "cropped_image.jpg", { type: "image/jpeg" });
            const croppedImageUrl = URL.createObjectURL(croppedImageFile);

            setImageFunction(croppedImageUrl); // Atualiza a imagem cortada
            setFileFunction(croppedImageFile); // Atualiza o arquivo de imagem
            setIsCropping(false); // Desativa o modo de corte
            setIsCroppingBackground(false); // Desativa o modo de corte
        } catch (e) {
            console.error(e);
        }
    };

    // Chamadas específicas para cada imagem
    const showCroppedProfileImage = () => showCroppedImage(imageSrc, setImageSrc, setProfileImage);
    const showCroppedBackgroundImage = () => showCroppedImage(backgroundSrc, setBackgroundSrc, setBackgroundImage);


    const handleSubmit = async (e) => {
        e.preventDefault();
        setShowSpinner(true)

        if (name === '' || email === '') {
            setShowSpinner(false)
            toast.error('Por favor preencha todos os campos.', { autoClose: 3000 });
            return;
        } else if (email.indexOf('@') === -1) {
            toast.error('O campo email deve conter o caractere @.', { autoClose: 3000 });
            return;
        } else if (password !== confPassword) {
            toast.error('Senha e confirmação de senha não conferem!', { autoClose: 3000 });
            setPassword('');
            setConfPassword('');
            return;
        } else if (password && password.length < 6) {
            toast.error('A senha deve possuir no mínimo 6 caracteres.', { autoClose: 3000 });
            setPassword('');
            setConfPassword('');
            return;
        } else if (employmentStartDate === '') {
            toast.error('O campo email deve conter o caractere @.', { autoClose: 3000 });
            return;
        }

        const formData = new FormData();
        formData.append('name', name);
        formData.append('email', email);
        formData.append('position_id', positionId);
        formData.append('organization', organization);
        formData.append('employment_start_date', employmentStartDate);
        formData.append('company_location', companyLocation);
        formData.append('professional_email', professionalEmail);
        formData.append('birth_date', birthDate);
        formData.append('interested_in', interestedIn);
        formData.append('language', language);
        formData.append('password', password);
        if (profileImage) {
            formData.append('profile_image', profileImage);
        }
        if (backgroundImage) {
            formData.append('background_image', backgroundImage);  // Adiciona a imagem de background
        }

        try {
            await api.post(`/api/user/updateProfile/${userId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            Cookies.set("userName", name, { expires: 7, secure: false });
            toast.success('Usuário editado com sucesso!', {
                toastId: customId,
                autoClose: 300,
                onClose: () => {
                    setShowEditModal(false)
                    fetchUserAbout(true);
                }
            });
        } catch (error) {
            setShowSpinner(false)
            console.error('Error updating user:', error);
            toast.error('Erro ao editar usuário!', { autoClose: 3000 });
        }
    };

    useEffect(() => {
        fetchUserAbout(true);
        checkFirstAccess();
    }, []);

    useEffect(() => {
        const fetchPositions = async () => {
            try {
                const response = await api.post("/api/positions/index");
                setPositions(response.data.positions);
            } catch (error) {
                console.error("Erro ao carregar cargos:", error);
                toast.error("Erro ao carregar cargos!", { autoClose: 3000 });
            }
        };

        fetchPositions();
    }, []);

    return (
        <>
            {!isLoading && (
                <Modal show={showEditModal} onHide={() => setShowEditModal(false)} size="xl" centered>
                    <Modal.Header closeButton>
                        <Modal.Title className='fs-1 d-flex align-items-center gap-2'>
                            <span className="material-symbols-outlined fs-1">
                                person_edit
                            </span>
                            <span>Editar perfil</span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="profile-header">
                            <div className="position-relative">
                                {isCroppingBackground ? (
                                    <>
                                        <div className="div-cropper">
                                            <Cropper
                                                className="cropper-area-background"
                                                image={backgroundSrc}
                                                crop={crop}
                                                zoom={zoom}
                                                aspect={8 / 2}
                                                onCropChange={setCrop}
                                                onZoomChange={setZoom}
                                                onCropComplete={onCropComplete}
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="div-imgBG-profile">
                                            <img
                                                loading="lazy"
                                                src={backgroundSrc || PlaceholderBackground}
                                                alt="profile-bg"
                                                className={`backgroundSrc-class ${backgroundSrc ? '' : 'PlaceholderBackground-class'}`} />
                                        </div>
                                    </>
                                )}
                                {isCroppingBackground && (
                                    <span onClick={showCroppedBackgroundImage} className="span-btn-cropper-background material-symbols-outlined z-3 me-2 text-white">check_circle</span>
                                )}
                            </div>
                        </div>
                        <Form noValidate onSubmit={handleSubmit} validated={validated} className="row g-3">
                            <Form.Group className="form-group">
                                <Col lg={12} className="text-rigth">
                                    <div className="profile-header">
                                        <div className="position-relative">
                                            <ul className="header-nav list-inline d-flex flex-wrap justify-end p-0 m-0">
                                                <li>
                                                    <div className="p-image p-image-background d-flex align-items-center justify-content-center shadow-sm me-3">
                                                        <span onClick={handleBackgroundButtonClick} className="material-symbols-outlined ">wallpaper</span>
                                                        <input ref={fileBackgroundInputRef} className="file-upload" onChange={handleFileBackgroundChange} type="file" accept="image/*" />
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </Col>
                            </Form.Group>
                            <Form.Group className="form-group align-items-center">
                                <Col lg={12} className="text-center profile-center-img">
                                    <div className="profile-img-edit">
                                        {isCropping ? (
                                            <>
                                                <div className="profile-class">
                                                    <Cropper
                                                        className="cropper-class"
                                                        image={imageSrc}
                                                        crop={crop}
                                                        zoom={zoom}
                                                        aspect={1 / 1}
                                                        onCropChange={setCrop}
                                                        onZoomChange={setZoom}
                                                        onCropComplete={onCropComplete}
                                                    />
                                                </div>
                                                <span onClick={showCroppedProfileImage} className="span-btn-cropper-profile material-symbols-outlined z-3 text-white">check_circle</span>
                                            </>
                                        ) : (
                                            <img
                                                className="profile-pic"
                                                src={imageSrc || PlaceholderProfile} // Placeholder para a imagem de perfil
                                                alt="profile-pic"
                                            />
                                        )}
                                        <div className="p-image d-flex align-items-center justify-content-center shadow-sm">
                                            <span onClick={handleButtonClick} className="material-symbols-outlined">frame_person</span>
                                            <input ref={fileInputRef} className="file-upload" onChange={handleFileChange} type="file" accept="image/*" />
                                        </div>
                                    </div>
                                </Col>
                            </Form.Group>

                            <Col md="6">
                                <Form.Label className="form-label fw-bold ms-2 mt-1" htmlFor="validationCustom01">
                                    Nome
                                </Form.Label>
                                <Form.Control type='text' placeholder='Nome' value={name} onChange={(e) => setName(e.target.value)} required />
                                <Form.Control.Feedback>
                                    Correto!
                                </Form.Control.Feedback>

                                <Form.Label className="form-label fw-bold ms-2 mt-1" htmlFor="validationCustom02">
                                    E-mail
                                </Form.Label>
                                <Form.Control type='email' placeholder='E-mail' value={email} onChange={(e) => setEmail(e.target.value)} required />
                                <Form.Control.Feedback>
                                    Correto!
                                </Form.Control.Feedback>

                                <Form.Label className="form-label fw-bold ms-2 mt-1" htmlFor="validationCustom05">
                                    Data de Nascimento
                                </Form.Label>
                                <Form.Control type='date' placeholder='Data de Nascimento' value={birthDate} onChange={(e) => setBirthDate(e.target.value)} />
                            </Col>

                            <Col md="6">
                                <Form.Label className="form-label fw-bold ms-2 mt-1" htmlFor="validationCustom08">
                                    Interesses
                                </Form.Label>
                                <Form.Control type='text' placeholder='Interesses' value={interestedIn} onChange={(e) => setInterestedIn(e.target.value)} />

                                <Form.Label className="form-label fw-bold ms-2 mt-1" htmlFor="validationCustom09">
                                    Idiomas Falados
                                </Form.Label>
                                <Form.Control type='text' placeholder='Idioma' value={language} onChange={(e) => setLanguage(e.target.value)} />

                            </Col>

                            <Col md="6">
                                <Form.Label className="form-label fw-bold ms-2 mt-1" htmlFor="validationCustom03">
                                    Cargo
                                </Form.Label>
                                <Form.Select
                                    id="validationCustom03"
                                    value={positionId}
                                    onChange={(e) => setPositionId(e.target.value)}
                                    className="form-control"
                                >
                                    <option value="">Selecione um cargo</option>
                                    {positions.map((position) => (
                                        <option key={position.id} value={position.id}>
                                            {position.name}
                                        </option>
                                    ))}
                                </Form.Select>

                                <Form.Label className="form-label fw-bold ms-2 mt-1" htmlFor="validationCustom03">
                                    Organização
                                </Form.Label>
                                <Form.Control type='text' placeholder='Empresa atual' value={organization} onChange={(e) => setOrganization(e.target.value)} />
                            </Col>
                            <Col md="6">
                                <Form.Label className="form-label fw-bold ms-2 mt-1" htmlFor="validationCustom03">
                                    Data de Inicio na Organização
                                </Form.Label>
                                <Form.Control type='date' value={employmentStartDate} onChange={(e) => setEmploymentStartDate(e.target.value)} />

                                <Form.Label className="form-label fw-bold ms-2 mt-1" htmlFor="validationCustom03">
                                    Localização da Organização
                                </Form.Label>
                                <Form.Control type='text' value={companyLocation} onChange={(e) => setCompanyLocation(e.target.value)} />

                                <Form.Label className="form-label fw-bold ms-2 mt-1" htmlFor="validationCustom03">
                                    Email Organizacional
                                </Form.Label>
                                <Form.Control type='text' value={professionalEmail} onChange={(e) => setProfessionalEmail(e.target.value)} />
                            </Col>

                            <div className="col-12">
                                <Button
                                    disabled={showSpinner}
                                    type="submit"
                                    variant="primary">
                                    {showSpinner ? <div className="spinner-border spinner-border-sm" role="status"></div> : <div className='fw-semibold'>Editar</div>}
                                </Button>
                            </div>
                        </Form>
                    </Modal.Body>
                </Modal>
            )}
        </>
    );
};

export default UserFirstAccessCheck;
