import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col, Card, Tab, Form, Button, Nav } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import api from '../../services/api';
import { toast } from 'react-toastify';
import Cropper from 'react-easy-crop';
import getCroppedImg from "../../components/getCroppedImag/getCroppedImg";
import PlaceholderBackground from "../../assets/images/logo-full.png";
import PlaceholderProfile from "../../assets/images/generic_user.png";
import './style-user-profile-edit.css'
import img1 from '../../assets/images/user/11.png';

const UserProfileEdit = () => {
    const [validated, setValidated] = useState(false);
    const customId = 'success-toast-id';
    const userId = Cookies.get('userId');
    const token = Cookies.get('token');

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [positions, setPositions] = useState([]);
    const [positionId, setPositionId] = useState("");
    const [organization, setOrganization] = useState('');
    const [workTeam, setWorkTeam] = useState('');
    const [employmentStartDate, setEmploymentStartDate] = useState('');
    const [companyLocation, setCompanyLocation] = useState('');
    const [professionalEmail, setProfessionalEmail] = useState('');
    const [birthDate, setBirthDate] = useState('');
    const [interestedIn, setInterestedIn] = useState('');
    const [language, setLanguage] = useState('');
    const [password, setPassword] = useState('');
    const [confPassword, setConfPassword] = useState('');
    const [imageSrc, setImageSrc] = useState(img1);
    const [profileImage, setProfileImage] = useState(null);
    const [backgroundImage, setBackgroundImage] = useState(null);
    const [backgroundSrc, setBackgroundSrc] = useState('');
    const [cropProfile, setCropProfile] = useState({ x: 0, y: 0 });
    const [zoomProfile, setZoomProfile] = useState(1);
    const [cropBackground, setCropBackground] = useState({ x: 0, y: 0 });
    const [zoomBackground, setZoomBackground] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [isCropping, setIsCropping] = useState(false);
    const [isCroppingBackground, setIsCroppingBackground] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false)

    const fileInputRef = useRef(null);
    const fileBackgroundInputRef = useRef(null);
    const history = useNavigate();

    useEffect(() => {
        const fetchPositions = async () => {
            try {
                const response = await api.post("/api/positions/index");
                setPositions(response.data.positions);
            } catch (error) {
                console.error("Erro ao carregar cargos:", error);
                toast.error("Erro ao carregar cargos!", { autoClose: 3000 });
            }
        };

        fetchPositions();
    }, []);

    useEffect(() => {
        let cancel = false;

        async function fetchData() {
            try {
                const response = await api.post(`/api/user/about/`, {
                    userId,
                    token,
                });

                if (!cancel) {
                    const data = response.data[0];
                    setName(data.user.name || '');
                    setEmail(data.user.email || '');
                    setPositionId(data.user.position_id || '');
                    setOrganization(data.user.organization || '');
                    setWorkTeam(data.team_name || '');
                    setEmploymentStartDate(data.user.employment_start_date || '');
                    setCompanyLocation(data.user.company_location || '');
                    setProfessionalEmail(data.user.professional_email || '');
                    setBirthDate(data.user.birth_date || '');
                    setInterestedIn(data.user.interested_in || '');
                    setLanguage(data.user.language || '');
                    setImageSrc(data.user.image_profile);
                    setBackgroundSrc(data.user.background_image || '');
                }
            } catch (error) {
                console.error('Failed to fetch user data:', error);
            }
        }

        fetchData();

        return () => {
            cancel = true;
        };
    }, [userId, token]);

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleBackgroundButtonClick = () => {
        fileBackgroundInputRef.current.click();
    };


    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            setProfileImage(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageSrc(reader.result);
                setIsCropping(true); // Habilita o modo de corte
            };
            reader.readAsDataURL(file);
        }
    };

    const handleFileBackgroundChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            setBackgroundImage(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setBackgroundSrc(reader.result);  // Atualiza o preview do background
                setIsCroppingBackground(true); // Habilita o modo de corte
            };
            reader.readAsDataURL(file);
        }
    };

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    };

    // Chamadas específicas para cada imagem
    const showCroppedProfileImage = async () => {
        try {
            const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
            const croppedImageFile = new File([croppedImage], "cropped_image.jpg", { type: "image/jpeg" });
            const croppedImageUrl = URL.createObjectURL(croppedImageFile);

            setImageSrc(croppedImageUrl);
            setProfileImage(croppedImageFile);
            setIsCropping(false);
        } catch (e) {
            console.error(e);
        }
    };

    const showCroppedBackgroundImage = async () => {
        try {
            const croppedImage = await getCroppedImg(backgroundSrc, croppedAreaPixels);
            const croppedImageFile = new File([croppedImage], "cropped_image.jpg", { type: "image/jpeg" });
            const croppedImageUrl = URL.createObjectURL(croppedImageFile);

            setBackgroundSrc(croppedImageUrl);
            setBackgroundImage(croppedImageFile);
            setIsCroppingBackground(false);
        } catch (e) {
            console.error(e);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setShowSpinner(true)

        if (name === '' || email === '') {
            setShowSpinner(false)
            toast.error('Por favor preencha todos os campos.', { autoClose: 3000 });
            return;
        } else if (email.indexOf('@') === -1) {
            setShowSpinner(false)
            toast.error('O campo email deve conter o caractere @.', { autoClose: 3000 });
            return;
        } else if (password !== confPassword) {
            setShowSpinner(false)
            toast.error('Senha e confirmação de senha não conferem!', { autoClose: 3000 });
            setPassword('');
            setConfPassword('');
            return;
        } else if (password && password.length < 6) {
            setShowSpinner(false)
            toast.error('A senha deve possuir no mínimo 6 caracteres.', { autoClose: 3000 });
            setPassword('');
            setConfPassword('');
            return;
        }

        const formData = new FormData();
        formData.append('name', name);
        formData.append('email', email);
        formData.append('position_id', positionId);
        formData.append('organization', organization);
        formData.append('team_name', workTeam);
        formData.append('employment_start_date', employmentStartDate);
        formData.append('company_location', companyLocation);
        formData.append('professional_email', professionalEmail);
        formData.append('birth_date', birthDate);
        formData.append('interested_in', interestedIn);
        formData.append('language', language);
        formData.append('password', password);
        if (profileImage) {
            formData.append('profile_image', profileImage);
        }
        if (backgroundImage) {
            formData.append('background_image', backgroundImage);  // Adiciona a imagem de background
        }

        try {
            await api.post(`/api/user/updateProfile/${userId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            setShowSpinner(false)

            Cookies.set("userName", name, { expires: 7, secure: false });
            toast.success('Usuário editado com sucesso!', {
                toastId: customId,
                autoClose: 300,
                onClose: () => {
                    history('/users/my-profile');
                }
            });
        } catch (error) {
            setShowSpinner(false)
            console.error('Error updating user:', error);
            toast.error('Erro ao editar usuário!', { autoClose: 3000 });
        }
    };


    return (
        <>
            <div className='content-inner'>
                <Container>
                    <Tab.Container defaultActiveKey="first">
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <Card.Body className="p-0">
                                        <div>
                                            <Nav as="ul" variant="pills" className="iq-edit-profile row mb-0">
                                                <Nav.Item as="li" className="col-md-6 p-0">
                                                    <Nav.Link eventKey="first" role="button">
                                                        Informação pessoal
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item as="li" className="col-md-6 p-0">
                                                    <Nav.Link eventKey="second" role="button">
                                                        Trocar senha
                                                    </Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={12}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first" className="fade show">
                                        <Card>
                                            <Card.Body>
                                                <div className="profile-header">
                                                    <div className="position-relative">
                                                        {isCroppingBackground ? (
                                                            <>
                                                                <div className="div-cropper">
                                                                    <Cropper
                                                                        className="cropper-area-background"
                                                                        image={backgroundSrc}
                                                                        crop={cropBackground}
                                                                        zoom={zoomBackground}
                                                                        aspect={8 / 2}
                                                                        onCropChange={setCropBackground}
                                                                        onZoomChange={setZoomBackground}
                                                                        onCropComplete={onCropComplete}
                                                                    />
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className="div-imgBG-profile div-img-edit-onmobile">
                                                                    <img
                                                                        loading="lazy"
                                                                        src={backgroundSrc || PlaceholderBackground}
                                                                        alt="profile-bg"
                                                                        className={`backgroundSrc-class img-onmobile ${backgroundSrc ? '' : 'PlaceholderBackground-class'}`} />
                                                                </div>
                                                            </>
                                                        )}
                                                        {isCroppingBackground && (
                                                            <span onClick={showCroppedBackgroundImage} className="span-btn-cropper-background material-symbols-outlined z-3">check_circle</span>
                                                        )}
                                                    </div>
                                                </div>
                                                <Form noValidate onSubmit={handleSubmit} validated={validated} className="row g-3">
                                                    <Form.Group className="form-group">
                                                        <Col lg={12} className="text-rigth">
                                                            <div className="profile-header">
                                                                <div className="position-relative">
                                                                    <ul className="header-nav list-inline d-flex flex-wrap justify-end p-0 m-0">
                                                                        <li>
                                                                            <div className="p-image p-image-background d-flex align-items-center justify-content-center shadow-sm">
                                                                                <span onClick={handleBackgroundButtonClick} className="material-symbols-outlined z-3">wallpaper</span>
                                                                                <input ref={fileBackgroundInputRef} className="file-upload" onChange={handleFileBackgroundChange} type="file" accept="image/*" />
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group className="form-group align-items-center">
                                                        <Col lg={12} className="text-center profile-center-img col-profile-onmobile">
                                                            <div className="profile-img-edit div-profile-onmobile">
                                                                {isCropping ? (
                                                                    <>
                                                                        <div className="profile-class">
                                                                            <Cropper
                                                                                className="cropper-class"
                                                                                image={imageSrc}
                                                                                crop={cropProfile}
                                                                                zoom={zoomProfile}
                                                                                aspect={1 / 1}
                                                                                onCropChange={setCropProfile}
                                                                                onZoomChange={setZoomProfile}
                                                                                onCropComplete={onCropComplete}
                                                                            />
                                                                        </div>
                                                                        <span onClick={showCroppedProfileImage} className="span-btn-cropper-profile material-symbols-outlined">check_circle</span>
                                                                    </>
                                                                ) : (
                                                                    <img
                                                                        className="profile-pic img-profile-pic"
                                                                        src={imageSrc || PlaceholderProfile} // Placeholder para a imagem de perfil
                                                                        alt="profile-pic"
                                                                    />
                                                                )}
                                                                <div className="p-image d-flex align-items-center justify-content-center shadow-sm">
                                                                    <span onClick={handleButtonClick} className="material-symbols-outlined">frame_person</span>
                                                                    <input ref={fileInputRef} className="file-upload" onChange={handleFileChange} type="file" accept="image/*" />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Form.Group>

                                                    <Col md="6">
                                                        <Form.Label className="form-label fw-bold ms-2 mt-1" htmlFor="validationCustom01">
                                                            Nome
                                                        </Form.Label>
                                                        <Form.Control type='text' placeholder='Nome' value={name} onChange={(e) => setName(e.target.value)} required />
                                                        <Form.Control.Feedback>
                                                            Correto!
                                                        </Form.Control.Feedback>

                                                        <Form.Label className="form-label fw-bold ms-2 mt-1" htmlFor="validationCustom02">
                                                            E-mail
                                                        </Form.Label>
                                                        <Form.Control type='email' placeholder='E-mail' value={email} onChange={(e) => setEmail(e.target.value)} required />
                                                        <Form.Control.Feedback>
                                                            Correto!
                                                        </Form.Control.Feedback>

                                                        <Form.Label className="form-label fw-bold ms-2 mt-1" htmlFor="validationCustom05">
                                                            Data de Nascimento
                                                        </Form.Label>
                                                        <Form.Control type='date' placeholder='Data de Nascimento' value={birthDate} onChange={(e) => setBirthDate(e.target.value)} />
                                                    </Col>

                                                    <Col md="6">
                                                        <Form.Label className="form-label fw-bold ms-2 mt-1" htmlFor="validationCustom08">
                                                            Interesses
                                                        </Form.Label>
                                                        <Form.Control type='text' placeholder='Interesses' value={interestedIn} onChange={(e) => setInterestedIn(e.target.value)} />

                                                        <Form.Label className="form-label fw-bold ms-2 mt-1" htmlFor="validationCustom09">
                                                            Idioma
                                                        </Form.Label>
                                                        <Form.Control type='text' placeholder='Idioma' value={language} onChange={(e) => setLanguage(e.target.value)} />

                                                    </Col>

                                                    <Col md="6">
                                                        <Form.Label className="form-label fw-bold ms-2 mt-1" htmlFor="validationCustom03">
                                                            Cargo
                                                        </Form.Label>
                                                        <Form.Select
                                                            id="validationCustom03"
                                                            value={positionId}
                                                            onChange={(e) => setPositionId(e.target.value)}
                                                            className="form-control"
                                                        >
                                                            <option value="">Selecione um cargo</option>
                                                            {positions.map((position) => (
                                                                <option key={position.id} value={position.id}>
                                                                    {position.name}
                                                                </option>
                                                            ))}
                                                        </Form.Select>

                                                        <Form.Label className="form-label fw-bold ms-2 mt-1" htmlFor="validationCustom03">
                                                            Organização
                                                        </Form.Label>
                                                        <Form.Control type='text' placeholder='Empresa atual' value={organization} onChange={(e) => setOrganization(e.target.value)} />

                                                        <Form.Label className="form-label fw-bold ms-2 mt-1" htmlFor="validationCustom03">
                                                            Organograma
                                                        </Form.Label>
                                                        <Form.Control
                                                            type='text'
                                                            placeholder='Equipe de trabalho atual'
                                                            value={workTeam || 'Sem equipe'}
                                                            onChange={(e) => setWorkTeam(e.target.value)}
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Col md="6">
                                                        <Form.Label className="form-label fw-bold ms-2 mt-1" htmlFor="validationCustom03">
                                                            Data de Inicio na Organização
                                                        </Form.Label>
                                                        <Form.Control type='date' value={employmentStartDate} onChange={(e) => setEmploymentStartDate(e.target.value)} />

                                                        <Form.Label className="form-label fw-bold ms-2 mt-1" htmlFor="validationCustom03">
                                                            Localização da Organização
                                                        </Form.Label>
                                                        <Form.Control type='text' value={companyLocation} onChange={(e) => setCompanyLocation(e.target.value)} />

                                                        <Form.Label className="form-label fw-bold ms-2 mt-1" htmlFor="validationCustom03">
                                                            Email Organizacional
                                                        </Form.Label>
                                                        <Form.Control type='text' value={professionalEmail} onChange={(e) => setProfessionalEmail(e.target.value)} />
                                                    </Col>

                                                    <div className="col-12">
                                                        <Button
                                                            disabled={showSpinner}
                                                            type="submit"
                                                            variant="primary">
                                                            {showSpinner ? <div className="spinner-border spinner-border-sm" role="status"></div> : <div className='fw-semibold'>Editar</div>}
                                                        </Button>
                                                    </div>
                                                </Form>
                                            </Card.Body>
                                        </Card>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second" className="fade show">
                                        <Card>
                                            <Card.Header className="d-flex justify-content-between">
                                                <div className="iq-header-title">
                                                    <h4 className="card-title">Trocar senha</h4>
                                                </div>
                                            </Card.Header>
                                            <Card.Body>
                                                <Form noValidate onSubmit={handleSubmit} validated={validated} className="row g-3">
                                                    <Col md="6">
                                                        <Form.Label className="form-label">
                                                            Senha
                                                        </Form.Label>
                                                        <Form.Group className="form-group align-items-center">
                                                            <Form.Control type='password' placeholder='Senha' value={password} onChange={(e) => setPassword(e.target.value)} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md="6">
                                                        <Form.Label className="form-label">
                                                            Confirmação de Senha
                                                        </Form.Label>
                                                        <Form.Group className="input-group">
                                                            <Form.Control type='password' placeholder='Confirmar senha' value={confPassword} onChange={(e) => setConfPassword(e.target.value)} />
                                                        </Form.Group>
                                                    </Col>
                                                    <div className="col-12">
                                                        <Button type="submit" variant="primary">Editar</Button>
                                                    </div>
                                                </Form>
                                            </Card.Body>
                                        </Card>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Container>
            </div>
        </>
    );
};

export default UserProfileEdit;
