import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import api from '../../services/api';
import { toast } from 'react-toastify';
import { Fireworks } from 'fireworks-js';
import { io } from 'socket.io-client';
import Cookies from 'js-cookie';

const SorteioWinner = ({ userId }) => {
    const [winners, setWinners] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isCurrentUserWinner, setIsCurrentUserWinner] = useState(false);
    const [sorteio_id, setSorteio_id] = useState(null);
    const [sorteio, setSorteio] = useState([]);
    const [isLoading, setIsLoading] = useState(false); // Estado para gerenciar o spinner
    const fireworksRef = useRef(null);
    const socket = useRef(null);
    const containerRef = useRef(null);

    useEffect(() => {
        if (showModal && containerRef.current) {
            const fireworks = new Fireworks(containerRef.current, {
                speed: 2,
                acceleration: 1.05,
                particles: 150,
                traceLength: 3,
                explosion: 5,
            });

            fireworks.start();
            fireworksRef.current = fireworks;
        }

        return () => {
            if (fireworksRef.current) {
                fireworksRef.current.stop();
            }
        };
    }, [showModal]);

    useEffect(() => {
        socket.current = io(process.env.REACT_APP_API_SOCKET_URL, {
            transports: ['polling', 'websocket'],
        });

        socket.current.on('connect', () => {
            socket.current.emit('register', 'newsFeed');
        });

        socket.current.on('send-sorteio-winners', () => {
            checkUserSubscription(userId);
        });

        return () => {
            if (socket.current) {
                socket.current.disconnect();
            }
        };
    }, []);

    const checkUserSubscription = async (userId) => {
        try {
            const response = await api.get(`/api/sorteios/active-subscription/${userId}`);
            if (response.data.sorteio_id) {
                setSorteio_id(response.data.sorteio_id);
                fetchWinners(response.data.sorteio_id);
            }
        } catch (error) {
            console.error('Erro ao verificar inscrição:', error);
        }
    };

    const fetchSorteio = async (sorteio_id) => {
        try {
            const response = await api.get(`/api/sorteio/edit/${sorteio_id}`);
            if (response.data) {
                setSorteio(response.data);
            } else {
                toast.error('Erro ao carregar o sorteio.');
            }
        } catch (error) {
            console.error('Erro ao carregar sorteio:', error);
        }
    };

    const fetchWinners = async (sorteio_id) => {
        try {
            const response = await api.get(`/api/sorteio/${sorteio_id}/winner`);
            const fetchedWinners = response.data.winners || [];

            setWinners(fetchedWinners);

            if (fetchedWinners && Array.isArray(fetchedWinners)) {
                const userIsWinner = fetchedWinners.some((winner) => Number(winner.user_id) === Number(userId));
                setIsCurrentUserWinner(userIsWinner);
            }

            if (fetchedWinners.length > 0) {
                setShowModal(true);
            }
        } catch (error) {
            console.error('Erro ao buscar ganhadores:', error);
        }
    };

    useEffect(() => {
        checkUserSubscription(userId);

        if (sorteio_id != null) {
            fetchSorteio(sorteio_id);
        }
    }, [userId, sorteio_id]);

    const handleCloseModal = async () => {
        setIsLoading(true); 
        try {
            await api.post(`/api/sorteio/${sorteio_id}/check-winner`);
            setShowModal(false);
            toast.success('Obrigado por participar!');
        } catch (error) {
            console.error('Erro ao atualizar status:', error);
            toast.error('Erro ao atualizar status.');
        } finally {
            setIsLoading(false); 
        }
    };

    return (
        <Modal show={showModal}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {isCurrentUserWinner ? 'Parabéns, você ganhou!' : 'Ganhadores do Sorteio: ' + sorteio.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div ref={containerRef} style={{ position: 'relative', width: '100%', maxHeight: '500px' }}>
                    {isCurrentUserWinner ? (
                        <>
                            <p>
                                Parabéns você é um dos ganhadores do sorteio <strong>{sorteio.title}</strong> 🎉
                            </p>
                            <p>Os ganhadores são:</p>
                            <ul>
                                {winners.map((winner) => (
                                    <li key={winner.user_id}>
                                        <img
                                            className="rounded-pill m-2"
                                            style={{ width: '2.5rem', height: '2.5rem' }}
                                            src={winner.image_profile}
                                            alt={winner.name}
                                        />
                                        <a href={'/users/profile/' + winner.user_id}>{winner.name}</a>
                                    </li>
                                ))}
                            </ul>
                        </>
                    ) : (
                        <>
                            <p>Os ganhadores são:</p>
                            <ul>
                                {winners.map((winner) => (
                                    <li key={winner.user_id}>
                                        <img
                                            className="rounded-pill m-2"
                                            style={{ width: '2.5rem', height: '2.5rem' }}
                                            src={winner.image_profile}
                                            alt={winner.name}
                                        />
                                        <a href={'/users/profile/' + winner.user_id}>{winner.name}</a>
                                    </li>
                                ))}
                            </ul>
                        </>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className="z-3" variant="secondary" onClick={handleCloseModal} disabled={isLoading}>
                    {isLoading ? (
                        <>
                            <Spinner animation="border" size="sm" role="status" aria-hidden="true" />
                            {' Fechando...'}
                        </>
                    ) : (
                        'Fechar'
                    )}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SorteioWinner;
