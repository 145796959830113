import React from "react";

// auth
import SignIn from "../views/auth/sign-in";
import SignUp from "../views/auth/sign-up";
import Recoverpw from "../views/auth/recoverpw";
import PasswordReset from "../views/auth/password-reset";
import Error404 from "../views/errors/error404";
import Error500 from "../views/errors/error500";


export const AuthRouter = [

  {
    path: "auth/sign-in",
    element: <SignIn />,
  },
  {
    path: "auth/sign-up/:token",
    element: <SignUp />,
  },
  {
    path: "auth/recoverpw",
    element: <Recoverpw />,
  },{
    path: "password-reset/:token",
    element: <PasswordReset />,
  },
  {
    path: "errors/error404",
    element: <Error404 />,
  },
  {
    path: "errors/error500",
    element: <Error500 />,
  },
];
