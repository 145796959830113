import React, { useState, useEffect } from "react";
import { Row, Col, Container, Button, Card } from "react-bootstrap";
import { useParams, Link } from "react-router-dom";
import api from "../../services/api";
import { toast } from "react-toastify";
import { Pie, Bar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    BarElement,
    CategoryScale,
    LinearScale,
    Title,
    ArcElement,
    Tooltip,
    Legend,
} from "chart.js";

const Chart = () => {
    ChartJS.register(
        CategoryScale,
        BarElement,
        LinearScale,
        Title,
        ArcElement,
        Tooltip,
        Legend
    );

    const customId = "success-toast-id";
    const { sorteio_id } = useParams();
    const [statusData, setStatusData] = useState([]);
    const [winnerData, setWinnerData] = useState([]);
    const [statusPercent, setStatusPercent] = useState([]);
    const [chartType, setChartType] = useState("pie"); // 'pie' ou 'bar'

    const statusChart = {
        labels: ["Aceitaram", "Recusaram", "Não Responderam"],
        datasets: [
            {
                label: "Status dos Convites",
                data: chartType === "pie" ? statusPercent : statusData,
                backgroundColor: [
                    "rgba(75, 192, 192, 0.6)",
                    "rgba(255, 99, 132, 0.6)",
                    "rgba(255, 206, 86, 0.6)",
                ],
                borderColor: [
                    "rgba(75, 192, 192, 1)",
                    "rgba(255, 99, 132, 1)",
                    "rgba(255, 206, 86, 1)",
                ],
                borderWidth: 1,
            },
        ],
    };

    const winnerChart = {
        labels: ["Participantes", "Ganhadores"],
        datasets: [
            {
                label: "Resumo do Sorteio",
                data: winnerData,
                backgroundColor: ["rgba(54, 162, 235, 0.6)", "rgba(153, 102, 255, 0.6)"],
                borderColor: ["rgba(54, 162, 235, 1)", "rgba(153, 102, 255, 1)"],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: "top",
            },
            tooltip: {
                callbacks: {
                    label: (tooltipItem) =>
                        chartType === "pie"
                            ? `${tooltipItem.label}: ${tooltipItem.raw.toFixed(2)}%`
                            : `${tooltipItem.label}: ${tooltipItem.raw}`,
                },
            },
        },
    };

    useEffect(() => {
        let cancel = false;

        async function fetchData() {
            try {
                const response = await api.get(`/api/sorteio/chart-data/${sorteio_id}`);
                if (!cancel) {
                    const { accepted, declined, not_responded } = response.data.statusData;
                    console.log(response.data.statusData);
                    const total = accepted + declined + not_responded;

                    setStatusData([accepted, declined, not_responded]);
                    setStatusPercent([
                        (accepted / total) * 100,
                        (declined / total) * 100,
                        (not_responded / total) * 100,
                    ]);
                    setWinnerData([
                        response.data.winnerData.participants,
                        response.data.winnerData.winners,
                    ]);
                }
            } catch (error) {
                console.error("Failed to fetch chart data:", error);
                toast.error("Erro ao recuperar dados do gráfico", {
                    toastId: customId,
                    autoClose: 1000,
                });
            }
        }

        fetchData();

        return () => {
            cancel = true;
        };
    }, [sorteio_id]);

    return (
        <>
            <div id="content-page" className="content-inner">
                <Container>
                    <Row>
                        <Col sm="12">
                            <Card className="position-relative inner-page-bg bg-primary p-5">
                                <div className="inner-page-title" style={{ display: "flex", justifyContent: "space-between", width: "-webkit-fill-available" }}>
                                    <h3 className="text-white">Relátorio Sorteio</h3>
                                    <Link to='/sorteio/index'>
                                        <Button>
                                            <i className="icon material-symbols-outlined d-flex">
                                                arrow_back
                                            </i>
                                        </Button>
                                    </Link>
                                </div>
                            </Card>
                        </Col>
                        <Col sm="12" lg="12">
                            <Card>
                                <Card.Header className="d-flex gap-2">
                                    <h4 className="card-title">Estatísticas do Sorteio</h4>
                                    <div className='header-title d-flex gap-2'>
                                        <Button
                                            onClick={() => setChartType("pie")}
                                            variant={chartType === "pie" ? "primary" : "outline-primary"}
                                        >
                                            <i className='material-symbols-outlined d-flex'> pie_chart </i>
                                        </Button>
                                        <Button
                                            onClick={() => setChartType("bar")}
                                            variant={chartType === "bar" ? "primary" : "outline-primary"}
                                        >
                                            <i className='material-symbols-outlined d-flex'> bar_chart </i>
                                        </Button>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col className="d-flex flex-column align-items-center">
                                            <h5>Status dos Convites</h5>
                                            {chartType === "pie" ? (
                                                <Pie
                                                    data={statusChart}
                                                    options={options}
                                                    style={{ maxWidth: "60vw", maxHeight: "60vh" }}
                                                />
                                            ) : (
                                                <Bar
                                                    data={statusChart}
                                                    options={options}
                                                    style={{ maxWidth: "60vw", maxHeight: "60vh" }}
                                                />
                                            )}

                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default Chart;
