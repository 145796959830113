import React, { useEffect, useState, useMemo } from 'react'
import api from '../../services/api'
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import Card from "../../components/Card"
import { Link, useNavigate } from "react-router-dom"
import { FaSearch } from 'react-icons/fa';
import Cookies from 'js-cookie'
import { toast } from 'react-toastify'

// images
import gi1 from "../../assets/images/page-img/gi-1.jpg"
import img1 from "../../assets/images/page-img/profile-bg1.jpg"

const Groups = () => {
  const loged_user = Cookies.get('userId')
  const [groups, setGroups] = useState([])
  const [groupsJoined, setGroupsJoined] = useState([])
  const [membersGroup, setMembersGroup] = useState([])
  const [search, setSearch] = useState('')
  const history = useNavigate()

  const memberCount = (group) => membersGroup.find(gm => gm.group_id === group)?.count > 999 ? '999+' : membersGroup.find(gm => gm.group_id === group)?.count || 0

  const getGroups = async () => {
    const response = await api.get(`/api/groups/index`)
    setGroups(response.data.group)
    setGroupsJoined(response.data.groups_joined)
    setMembersGroup(response.data.groups_members)
  }

  const leaveGroup = async (group_id) => {
    const response = await api.post(`/api/group/leaveGroup/${group_id}`)
    getGroups()
  }

  const joinGroup = async (group_id) => {
    const response = await api.post(`/api/group/joinGroup/${group_id}`)
    getGroups()
  }

  const requestToJoin = async (group_id) => {
    await api.post(`/api/sendMemberGroupRequest`, {
      group_id,
      loged_user
    }).then(res => {
      if (res.data.message) {
        toast.error(res.data.message, { autoClose: 3000 })
      } else {
        toast.success('Solicitação enviada com sucesso', { autoClose: 3000 })
      }
    }).catch(err => {
      toast.error('Erro ao enviar solicitação', { autoClose: 3000 })
    })
  }

  const showCreateGroupPage = () => {
    history(`/group/create`)
  }

  useEffect(() => {
    getGroups()
  }, [])

  const filteredGroups = useMemo(() => {
    const lowerSearch = search.toLowerCase()

    if (search === '') {
        return groups
    } else {
        return groups.filter((group) => group.name.toLowerCase().includes(lowerSearch) || group.description.toLowerCase().includes(lowerSearch) || group.id.toString().toLowerCase().includes(lowerSearch))
    }
}, [search, groups])

  return (
    <>
      <div id="content-page" className="content-inner">
        <Container>
          <Row>
            <Col sm="12">
              <div className="card-header d-flex justify-content-between px-0 pb-4 mx-5">
                <div className="header-title">
                  <h5 className="fw-semibold">Grupos</h5>
                </div>
                <div className="d-flex">
                  <div className="mx-2">
                    <Button onClick={() => showCreateGroupPage()} className="d-flex">
                      <div className="d-none d-lg-block d-sm-block">
                        Adicionar grupo
                      </div>
                      <span className="material-symbols-outlined mt-0 mb-0">
                        add
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
            <div className="mb-4 mt-4">
              <Form.Group className="input-group">
                <span className="input-group-text" id="basic-addon1"><FaSearch /></span>
                <Form.Control
                  placeholder='Pesquisar'
                  aria-label='Pesquisar'
                  aria-describedby='search'
                  onChange={(e) => setSearch(e.target.value)}
                />
              </Form.Group>
            </div>
            {filteredGroups.map((group, index) => (
              <Col md={6} key={index}>
                <Card className="card-block card-stretch card-height">
                  <div className="top-bg-image">
                    <img src={group.background && group.background !== '' ? group.background : img1} 
                    width={'100%'}
                    height={'200vh'} alt="group-bg" />
                  </div>
                  <Card.Body className="text-center">
                    {
                      groupsJoined.includes(group.id) || group.type === '2' ? (
                        <Link to={`/groups/group-detail/${group.id}`} className="h4">
                          <div className="group-icon">
                              <img
                                src={group.photo && group.photo !== '' ? group.photo : gi1}
                                alt="profile-img"
                                className="rounded-2 img-fluid avatar-90 border border-4"
                              />
                          </div>
                        </Link>
                      ) : (
                        <div className="group-icon">
                          <img
                            src={group.photo && group.photo !== '' ? group.photo : gi1}
                            alt="profile-img"
                            className="rounded-2 img-fluid avatar-90 border border-4"
                          />
                        </div>
                      )
                    }

                    {
                      groupsJoined.includes(group.id) || group.type === '2' ? (
                        <div className="group-info mt-3 mb-2">
                          <Link to={`/groups/group-detail/${group.id}`} className="h4">
                            {group.name}
                          </Link>
                        </div>
                      ) : (
                        <div className="group-info mt-3 mb-2 h4">
                          {group.name}
                        </div>
                      )
                    }

                    <div className="group-details pb-4 mb-4 border-bottom">
                      <ul className="d-flex align-items-center justify-content-center list-inline m-0 p-0 gap-3">
                        <li>
                          <div className="d-flex align-items-center gap-1">
                            <span className="material-symbols-outlined font-size-18">
                              { group.type === '1' ? 'Lock' : 'visibility' }
                            </span>
                            <span className="text-capitalize">{ group.type === '1' ? 'Privado' : 'Público' }</span>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex align-items-center gap-1">
                            <span className="material-symbols-outlined font-size-18">
                              group
                            </span>
                            <span className="text-capitalize">{memberCount(group.id) == 1 ? memberCount(group.id) + ' Membro' : memberCount(group.id) + ' Membros'}</span>
                          </div>
                        </li>
                      </ul>
                      <span className="font-size-18">
                        {group.description}
                      </span>
                    </div>
                    {groupsJoined.includes(group.id) ? (
                      <button
                        onClick={() => leaveGroup(group.id)}
                        className="btn btn-danger-subtle fw-semibold px-3"
                      >
                        Sair do Grupo
                      </button>
                    )
                      :
                      group.type === '2' ? (
                        <button
                          onClick={() => joinGroup(group.id)}
                          className="btn btn-primary-subtle fw-semibold px-3"
                        >
                          Juntar-se ao Grupo
                        </button>
                      ) : (
                        <button
                          onClick={() => requestToJoin(group.id)}
                          className="btn btn-primary-subtle fw-semibold px-3"
                        >
                          Pedir para entrar
                        </button>
                      )
                    }
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  )
}

export default Groups
