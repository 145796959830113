import React, { useState, useEffect, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import api from "../../services/api";
import { toast } from "react-toastify";
import { io } from 'socket.io-client'

const SorteioInviteModal = ({ userId }) => {
    const [showSpinner, setShowSpinner] = useState(false)
    const [showSpinnerAccept, setShowSpinnerAccept] = useState(false)
    const [showInviteModal, setShowInviteModal] = useState(false);
    const [sorteio, setCurrentSorteio] = useState(null);
    const [inviteId, setInviteId] = useState(null);
    const [hasError, setHasError] = useState(false);
    const socket = useRef(null)



    useEffect(() => {
        socket.current = io(process.env.REACT_APP_API_SOCKET_URL, {
            transports: ['polling', 'websocket'],
        })

        socket.current.on('connect', () => {
            socket.current.emit('register', 'newsFeed')
        })

        socket.current.on('send-sorteio-invite', () => {
            checkForInvites(userId);
        });

        socket.current.on('send-sorteio-invitegp', () => {
            checkForInvites(userId);
        });

        return () => {
            if (socket.current) {
                socket.current.disconnect()
            }
        }
    }, [])

    useEffect(() =>{

        if (!hasError) {
            checkForInvites(userId);
        }
        
    })

    const checkForInvites = async (userId) => {
        try {

            const response = await api.get(`/api/sorteio/invite/${userId}`);
            if (response.data.sorteio) {
                setCurrentSorteio(response.data.sorteio);
                setInviteId(response.data.inviteId)
                setShowInviteModal(true);
            }
        } catch (error) {
            if (error.response && error.response.status === 500) {
                setHasError(true); // Define o estado como true para evitar novas execuções
            }
            
        }
    };


    const handleAcceptInvite = async () => {
        try {

            setShowSpinnerAccept(true)

            const response = await api.post(`/api/sorteios/invite/${inviteId}/accept`);
            if (response.data.ticket) {
                toast.success(`Convite aceito! Seu ticket é: ${response.data.ticket}`);
                setShowInviteModal(false)
                setShowSpinnerAccept(false)
            } else {
                toast.info(response.data.message);
            }
        } catch (error) {
            console.error('Erro ao aceitar o convite:', error);
            toast.error('Não foi possível aceitar o convite.');
        }
    };

    const handleDeclineInvite = async () => {
        try {

            setShowSpinner(true)

            const response = await api.post(`/api/sorteios/invite/${inviteId}/decline`);
            if (!response.data) {
                toast.success('Erro ao recusar convite !');

            } else {
                toast.success(response.data.message);
                setShowInviteModal(false)
                setShowSpinner(false)
            }
        } catch (error) {
            console.error('Erro ao recusar o convite:', error);
            toast.error('Não foi possível recusar o convite.');
        }
    };


    return (
        <Modal show={showInviteModal} centered>
            <Modal.Header closeButton className="">
                <Modal.Title>Convite para Sorteio</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Você foi convidado para participar do sorteio:</p>
                <h5 className="mb-3">{sorteio?.title || "Título do Sorteio"}</h5>
                <p>{sorteio?.descricao || "Descrição do sorteio."}</p>
            </Modal.Body>
            <Modal.Footer className="">
                <Button variant="secondary" disabled={showSpinner} onClick={handleDeclineInvite}>
                    {showSpinner ? <div className="spinner-border spinner-border-sm" role="status"></div> : 'Recusar'}
                </Button>
                <Button variant="primary" disabled={showSpinnerAccept} onClick={handleAcceptInvite}>
                    {showSpinnerAccept ? <div className="spinner-border spinner-border-sm" role="status"></div> : 'Participar'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SorteioInviteModal;
