import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Form, Table } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import api from '../../services/api';
import { toast } from 'react-toastify';

const SorteioBlacklist = () => {
    const { sorteio_id } = useParams();
    const [users, setUsers] = useState([]);
    const [blacklist, setBlacklist] = useState([]);
    const [showSpinner, setShowSpinner] = useState(false);
    const [selectedUser, setSelectedUser] = useState("");

    // Carregar usuários e blacklist atual do sorteio
    useEffect(() => {
        const fetchData = async () => {
            try {
                setShowSpinner(true);

                // Carregar todos os usuários
                const usersResponse = await api.get('/api/sorteios/getusers');
                console.log(usersResponse)
                setUsers(usersResponse.data.users || []);

                // Carregar blacklist do sorteio
                const blacklistResponse = await api.get(`/api/sorteios/${sorteio_id}/blacklist`);
                console.log(usersResponse)
                setBlacklist(blacklistResponse.data.blacklist || []);

                setShowSpinner(false);
            } catch (error) {
                console.error('Erro ao carregar dados:', error);
                toast.error('Erro ao carregar dados.');
                setShowSpinner(false);
            }
        };

        fetchData();
    }, [sorteio_id]);


    // Salvar blacklist
    const handleSaveBlacklist = async () => {
        try {
            setShowSpinner(true);
            
            // Extrair apenas os IDs dos usuários na blacklist
            const blacklistIds = blacklist.map((user) => user.id || user);
    
            await api.post(`/api/sorteios/${sorteio_id}/blacklist/save`, { blacklist: blacklistIds });
            toast.success('Blacklist salva com sucesso!');
            setShowSpinner(false);
        } catch (error) {
            console.error('Erro ao salvar blacklist:', error);
            toast.error('Erro ao salvar blacklist.');
            setShowSpinner(false);
        }
    };
    

    const fetchBlacklist = async () => {
        try {
            const response = await api.get(`/api/sorteios/${sorteio_id}/blacklist`);
            setBlacklist(response.data.blacklist);
        } catch (error) {
            console.error("Erro ao carregar blacklist:", error);
            toast.error("Erro ao carregar blacklist.");
        }
    };

    const handleAddToBlacklist = async () => {
        try {
            await api.post(`/api/sorteios/${sorteio_id}/blacklist/add`, { user_id: selectedUser });
            toast.success("Usuário adicionado à blacklist.");
            fetchBlacklist();
            setSelectedUser("");
        } catch (error) {
            console.error("Erro ao adicionar usuário à blacklist:", error);
            toast.error("Erro ao adicionar usuário à blacklist.");
        }
    };

    const handleRemoveFromBlacklist = async (userId) => {
        try {
            await api.delete(`/api/sorteios/${sorteio_id}/blacklist/remove/${userId}`);
            toast.success("Usuário removido da blacklist.");
            fetchBlacklist();
        } catch (error) {
            console.error("Erro ao remover usuário da blacklist:", error);
            toast.error("Erro ao remover usuário da blacklist.");
        }
    };

    return (
        <div id='content-page' className='content-inner'>
            <Container>
                <Row>
                    <Col sm='12'>
                        <Card className='position-relative inner-page-bg bg-primary p-5'>
                            <div
                                className='inner-page-title'
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '-webkit-fill-available',
                                }}
                            >
                                <h3 className='text-white'>Blacklist do Sorteio</h3>
                                <Link to='/sorteio/index'>
                                    <Button>
                                        <i className='icon material-symbols-outlined d-flex'>arrow_back</i>
                                    </Button>
                                </Link>
                            </div>
                        </Card>
                    </Col>

                    <Col sm='12'>
                        <Card>
                            <Card.Header>
                                <h4 className='card-title'>Gerenciar Blacklist</h4>
                            </Card.Header>
                            <Card.Body>
                                <Form.Group className="d-flex gap-2 mb-3">
                                    <Form.Select
                                        value={selectedUser}
                                        onChange={(e) => setSelectedUser(e.target.value)}
                                        className='w-50'
                                    >
                                        <option value="">Selecione um usuário</option>
                                        {Array.isArray(users) && users.length > 0 ? (
                                            users.map((user) => (
                                                <option key={user.id} value={user.id}>
                                                    {user.name} ({user.email})
                                                </option>
                                            ))
                                        ) : (
                                            <option disabled>Carregando usuários...</option>
                                        )}
                                    </Form.Select>

                                    <Button onClick={handleAddToBlacklist} disabled={!selectedUser}>
                                        Adicionar à Blacklist
                                    </Button>
                                </Form.Group>

                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Nome</th>
                                            <th>Email</th>
                                            <th>Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {blacklist.map((item) => (
                                            <tr key={item.id}>
                                                <td>{item.user.name}</td>
                                                <td>{item.user.email}</td>
                                                <td>
                                                    <Button
                                                        variant="danger"
                                                        onClick={() => handleRemoveFromBlacklist(item.user.id)}
                                                    >
                                                        Remover
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default SorteioBlacklist;
