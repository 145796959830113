import React from "react";
import LiveLayout from "../layouts/dashboard/live-layout";
import LiveBrowser from "../views/lives/live";

export const LiveBrowserRouter = [
  {
    path: "streaming",
    element: <LiveLayout />,
    children: [
      {
        path: "live/:id",
        element: <LiveBrowser />,
      },
    ],
  },
];
