import React, { useState, useContext, useEffect } from 'react'

//router
import { Link, useLocation } from 'react-router-dom'

//react-bootstrap
import { Accordion, useAccordionButton, AccordionContext, Nav, Tooltip, OverlayTrigger } from 'react-bootstrap'
import { useNotification } from '../../../../context/NotificationContext';
import Cookies from 'js-cookie'
import api from '../../../../services/api';
import './styles.css'

function CustomToggle({ children, eventKey, onClick }) {

    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(eventKey, (active) => onClick({ state: !active, eventKey: eventKey }));

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
        <Link to="#" aria-expanded={isCurrentEventKey ? 'true' : 'false'} className="nav-link" role="button" onClick={(e) => {
            decoratedOnClick(isCurrentEventKey)
        }}>
            {children}
        </Link>
    );
}

const VerticalNav = React.memo(({ isSidebarMini }) => {
    const [activeMenu, setActiveMenu] = useState(false)
    const [active, setActive] = useState('')
    const logged_user = Cookies.get('userId')
    const [userPermissions, setUserPermissions] = useState([])
    //location
    let location = useLocation();
    const { isNewNotification, fetchNotification } = useNotification();

    const getUserPermisssions = async () => {
        try {
            const [userPermissionsResponse] = await Promise.all([
                api.get(`/api/permissions/user/${logged_user}`)
            ])

            setUserPermissions(userPermissionsResponse.data.userPermissions)
        } catch (error) {
            console.error('Erro')
        }
    }

    useEffect(() => {
        fetchNotification()
        getUserPermisssions()
    }, []);


    return (
        <React.Fragment>
            <Accordion as="ul" className="navbar-nav iq-main-menu" id="sidebar-menu">
                <li className="nav-item static-item">
                    <Link className="nav-link static-item disabled" to="#" tabIndex="-1">
                    </Link>
                </li>
                <li className={`${location.pathname === '/' ? 'active' : ''} nav-item `}>
                    <Link className={`${location.pathname === '/' ? 'active' : ''} nav-link `} id="feed" aria-current="page" to="/">
                        <OverlayTrigger placement="right" overlay={<Tooltip>Newsfeed</Tooltip>}>
                            <i className="icon material-symbols-outlined font-size-onmobile">
                                newspaper
                            </i>
                        </OverlayTrigger>
                        <span className="item-name font-size-onmobile">Newsfeed</span>
                    </Link>
                </li>
                <Accordion.Item as="li" eventKey="friends-menu" bsPrefix="nav-item">
                    <CustomToggle eventKey="friends-menu" onClick={(activeKey) => setActiveMenu(activeKey)}>

                        <OverlayTrigger placement="right" overlay={<Tooltip>Amigos</Tooltip>}>
                            <i className="icon material-symbols-outlined font-size-onmobile">
                                people
                            </i>
                        </OverlayTrigger>
                        <span className="item-name font-size-onmobile">Amigos</span>
                        {/* <i className="right-icon material-symbols-outlined">chevron_right</i> */}
                    </CustomToggle>
                    <Accordion.Collapse eventKey="friends-menu" >
                        <ul className="sub-nav">
                            <Nav.Item as="li">
                                <Link className={`${location.pathname === '/friends/friend-list' ? 'active' : ''} nav-link`} to="/friends/friend-list">
                                    <i className="icon material-symbols-outlined filled">
                                        fiber_manual_record
                                    </i>

                                    <OverlayTrigger placement="right" overlay={<Tooltip>Meus amigos</Tooltip>}>
                                        <i className="sidenav-mini-icon font-size-onmobile"> MA </i>
                                    </OverlayTrigger>
                                    <span className="item-name font-size-onmobile">Meus amigos</span>
                                </Link>
                            </Nav.Item>
                            <Nav.Item as="li">
                                <Link className={`${location.pathname === '/friends/friend-request' ? 'active' : ''} nav-link`} to="/friends/friend-request">
                                    <i className="icon material-symbols-outlined filled">
                                        fiber_manual_record
                                    </i>
                                    <OverlayTrigger placement="right" overlay={<Tooltip>Solicitações</Tooltip>}>
                                        <i className="sidenav-mini-icon font-size-onmobile"> SA </i>
                                    </OverlayTrigger>
                                    <span className="item-name font-size-onmobile">Solicitações</span>
                                </Link>
                            </Nav.Item>
                        </ul>
                    </Accordion.Collapse>
                </Accordion.Item>
                <Nav.Item as="li">
                    <Link className={`${location.pathname === '/groups/groups' ? 'active' : ''} nav-link `} aria-current="page" to="/groups/groups">
                        <OverlayTrigger placement="right" overlay={<Tooltip>Group</Tooltip>}>
                            <i className="icon material-symbols-outlined font-size-onmobile">
                                groups
                            </i>
                        </OverlayTrigger>
                        <span className="item-name font-size-onmobile">Grupos</span>
                    </Link>
                </Nav.Item>
                <Nav.Item as="li">
                    <Link className={`${location.pathname === '/events/index' ? 'active' : ''} nav-link `} aria-current="page" to="/events/index">
                        <OverlayTrigger placement="right" overlay={<Tooltip>Eventos</Tooltip>}>
                            <i className="icon material-symbols-outlined font-size-onmobile">
                                event
                            </i>
                        </OverlayTrigger>
                        <span className="item-name font-size-onmobile">Eventos</span>
                    </Link>
                </Nav.Item>
                <Nav.Item as="li">
                    <Link className={`${location.pathname === '/notifications/notification' ? 'active' : ''} nav-link `} aria-current="page" to="/notifications/notification">
                        <OverlayTrigger placement="right" overlay={<Tooltip>Notificações</Tooltip>}>
                            <i className="icon material-symbols-outlined font-size-onmobile">
                                notifications
                                {isNewNotification && isSidebarMini === true &&
                                    <i className="bg-primary text-white notification-badge-read"></i>
                                }
                            </i>
                        </OverlayTrigger>
                        <span className="item-name font-size-onmobile">Notificações
                            {isNewNotification && isSidebarMini === false &&
                                <span className="bg-primary text-white notification-badge-read"></span>
                            }
                        </span>
                    </Link>
                </Nav.Item>
                <Nav.Item as="li">
                    <Link className={`${location.pathname === '/knowledge/list' ? 'active' : ''} nav-link `} aria-current="page" to="/knowledge/list" >
                        <OverlayTrigger placement="right" overlay={<Tooltip>Conhecimentoteca</Tooltip>}>
                            <i className="icon material-symbols-outlined font-size-onmobile">
                                school
                            </i>
                        </OverlayTrigger>
                        <span className="item-name font-size-onmobile">Conhecimentoteca</span>
                    </Link>
                </Nav.Item>

                {
                    userPermissions.some((permission) => permission.name === 'adm_lives') ? (
                        <Nav.Item as="li">
                            <Link className={`${location.pathname === '/streaming/index' ? 'active' : ''} nav-link `} aria-current="page" to="/streaming/index" >
                                <OverlayTrigger placement="right" overlay={<Tooltip>Lives</Tooltip>}>
                                    <i className="icon material-symbols-outlined font-size-onmobile">
                                        videocam
                                    </i>
                                </OverlayTrigger>
                                <span className="item-name font-size-onmobile">Lives</span>
                            </Link>
                        </Nav.Item>
                    ) : ''
                }

                <Nav.Item as="li">
                    <Link className={`${location.pathname === '/vods/index' ? 'active' : ''} nav-link `} aria-current="page" to="/vods/index" >
                        <OverlayTrigger placement="right" overlay={<Tooltip>VODs</Tooltip>}>
                            <i className="icon material-symbols-outlined font-size-onmobile">
                                video_library
                            </i>
                        </OverlayTrigger>
                        <span className="item-name font-size-onmobile">VODs</span>
                    </Link>
                </Nav.Item>

                <Accordion.Item as="li" eventKey="gestao-menu" bsPrefix="nav-item">
                    {
                        userPermissions.some((permission) => permission.name === 'see_control_panel') ? (
                            <CustomToggle eventKey="gestao-menu" onClick={(activeKey) => setActiveMenu(activeKey)}>
                                <OverlayTrigger placement="right" overlay={<Tooltip>Gestão</Tooltip>}>
                                    <i className="icon material-symbols-outlined font-size-onmobile">
                                        admin_panel_settings
                                    </i>
                                </OverlayTrigger>
                                <span className="item-name font-size-onmobile">Gestão</span>
                            </CustomToggle>
                        ) : ''
                    }

                    <Accordion.Collapse eventKey="gestao-menu" >
                        <ul className="sub-nav">
                            <Nav.Item as="li">
                                <Link className={`${location.pathname === '/users/index' ? 'active' : ''} nav-link`} to="/users/index">

                                    <i className="icon material-symbols-outlined font-size-onmobile">
                                        manage_accounts
                                    </i>

                                    <OverlayTrigger placement="right" overlay={<Tooltip>Usuários</Tooltip>}>
                                        <i className="sidenav-mini-icon font-size-onmobile"> US </i>
                                    </OverlayTrigger>
                                    <span className="item-name font-size-onmobile">Usuários</span>
                                </Link>
                            </Nav.Item>
                            <Nav.Item as="li">
                                <Link className={`${location.pathname === '/permissions/index' ? 'active' : ''} nav-link`} to="/permissions/index">

                                    <i className="icon material-symbols-outlined font-size-onmobile">
                                        shield_person
                                    </i>

                                    <OverlayTrigger placement="right" overlay={<Tooltip>Permissões</Tooltip>}>
                                        <i className="sidenav-mini-icon font-size-onmobile"> US </i>
                                    </OverlayTrigger>
                                    <span className="item-name font-size-onmobile">Permissões</span>
                                </Link>
                            </Nav.Item>
                            <Nav.Item as="li">
                                <Link className={`${location.pathname === '/roles/index' ? 'active' : ''} nav-link`} to="/roles/index">

                                    <i className="icon material-symbols-outlined">
                                        supervisor_account
                                    </i>

                                    <OverlayTrigger placement="right" overlay={<Tooltip>Roles</Tooltip>}>
                                        <i className="sidenav-mini-icon font-size-onmobile"> US </i>
                                    </OverlayTrigger>
                                    <span className="item-name font-size-onmobile">Perfis</span>
                                </Link>
                            </Nav.Item>
                            <Nav.Item as="li">
                                <Link className={`${location.pathname === '/activitylogs/index' ? 'active' : ''} nav-link`} to="/activitylogs/index">

                                    <i className="icon material-symbols-outlined">
                                        activity_zone
                                    </i>

                                    <OverlayTrigger placement="right" overlay={<Tooltip>Logs</Tooltip>}>
                                        <i className="sidenav-mini-icon font-size-onmobile"> US </i>
                                    </OverlayTrigger>
                                    <span className="item-name font-size-onmobile">Logs</span>
                                </Link>
                            </Nav.Item>
                            <Nav.Item as="li">
                                <Link className={`${location.pathname === '/positions/index' ? 'active' : ''} nav-link`} to="/positions/index">
                                
                                    <i className="icon material-symbols-outlined">
                                    tenancy
                                    </i>

                                    <OverlayTrigger placement="right" overlay={<Tooltip>Cargos</Tooltip>}>
                                        <i className="sidenav-mini-icon font-size-onmobile"> US </i>
                                    </OverlayTrigger>
                                    <span className="item-name font-size-onmobile">Cargos</span>
                                </Link>
                            </Nav.Item>
                        </ul>
                    </Accordion.Collapse>
                </Accordion.Item>

                <Accordion.Item as="li" eventKey="interactions-menu" bsPrefix="nav-item">
                    <CustomToggle eventKey="interactions-menu" onClick={(activeKey) => setActiveMenu(activeKey)}>
                        <OverlayTrigger placement="right" overlay={<Tooltip>Interações</Tooltip>}>
                            <i className="icon material-symbols-outlined font-size-onmobile">
                                touch_app
                            </i>
                        </OverlayTrigger>
                        <span className="item-name font-size-onmobile">Interações</span>
                    </CustomToggle>

                    <Accordion.Collapse eventKey="interactions-menu" >
                        <ul className="sub-nav">
                            <Nav.Item as="li">
                                <Link className={`${location.pathname === '/quiz/index' ? 'active' : ''} nav-link`} to="/quiz/index">
                                    <i className="icon material-symbols-outlined font-size-onmobile">
                                        quiz
                                    </i>

                                    <OverlayTrigger placement="right" overlay={<Tooltip>Quiz</Tooltip>}>
                                        <i className="sidenav-mini-icon font-size-onmobile"> US </i>
                                    </OverlayTrigger>
                                    <span className="item-name font-size-onmobile">Quiz</span>
                                </Link>
                            </Nav.Item>
                            <Nav.Item as="li">
                                <Link className={`${location.pathname === '/polls/index' ? 'active' : ''} nav-link`} to="/polls/index">
                                    <i className="icon material-symbols-outlined ">
                                        ballot
                                    </i>

                                    <OverlayTrigger placement="right" overlay={<Tooltip>Enquete</Tooltip>}>
                                        <span className="sidenav-mini-icon font-size-onmobile">
                                            ballot
                                        </span>
                                    </OverlayTrigger>
                                    <span className="item-name font-size-onmobile">Enquete</span>
                                </Link>
                            </Nav.Item>
                            <Nav.Item as="li">
                                <Link className={`${location.pathname === '/sorteio/index' ? 'active' : ''} nav-link`} to="/sorteio/index">
                                    <i className="icon material-symbols-outlined ">
                                        trophy
                                    </i>

                                    <OverlayTrigger placement="right" overlay={<Tooltip>Sorteio</Tooltip>}>
                                        <span className="sidenav-mini-icon material-symbols-outlined">
                                            trophy
                                        </span>
                                    </OverlayTrigger>
                                    <span className="item-name font-size-onmobile">Sorteio</span>
                                </Link>
                            </Nav.Item>
                        </ul>
                    </Accordion.Collapse>
                </Accordion.Item>

                <Nav.Item as="li">
                    <Link className={`${location.pathname === '/organograma/index' ? 'active' : ''} nav-link `} aria-current="page" to="/organograma/index" >
                        <OverlayTrigger placement="right" overlay={<Tooltip>Organograma</Tooltip>}>
                            <i className="icon material-symbols-outlined font-size-onmobile">
                                account_tree
                            </i>
                        </OverlayTrigger>
                        <span className="item-name font-size-onmobile">Organograma</span>
                    </Link>
                </Nav.Item>
                <Nav.Item as="li">
                    <Link className={`${location.pathname === '/equipes/index' ? 'active' : ''} nav-link `} aria-current="page" to="/equipes/index" >
                        <OverlayTrigger placement="right" overlay={<Tooltip>Organograma</Tooltip>}>
                            <i className="icon material-symbols-outlined font-size-onmobile">
                                groups
                            </i>
                        </OverlayTrigger>
                        <span className="item-name font-size-onmobile">Equipes</span>
                    </Link>
                </Nav.Item>

            </Accordion>
        </React.Fragment >
    )
})

export default VerticalNav
