import React, { useEffect, useState, useRef, useCallback, useLayoutEffect } from 'react'
import {
  Row,
  Col,
  Container,
  Button
} from 'react-bootstrap'
import Card from '../components/Card'
import CreatePost from '../components/CreatePost/create-post'
import UserFirstAccessCheck from '../components/UserFirstAccessCheck'
import api from '../services/api'
import { useDispatch, useSelector } from 'react-redux'
import { set_active_users } from '../store/setting/actions'
import { io } from 'socket.io-client'

import { useInView } from 'react-intersection-observer'

//image
import loader from '../assets/images/page-img/page-load-loader.gif'
import userGenericImage from '../assets/images/generic_user.png'
import user5 from '../assets/images/page-img/fun.webp'
import boyImg from '../assets/images/page-img/boy.webp'
import busImg from '../assets/images/page-img/bus.webp'
import img11 from '../assets/images/page-img/fd.webp'
import mountain from '../assets/images/page-img/mountain.webp'
import pizza from '../assets/images/page-img/pizza.webp'
import LivePost from '../components/LivePost'
import Post from '../components/Post'
import EventPost from '../components/EventPost'
import PollPost from '../components/PollPost'
import QuizPost from '../components/QuizPost'
import SorteioInviteModal from '../components/SorteioModal'
import SorteioWinners from '../components/SorteioModal/winner'
import Cookies from 'js-cookie';

// FsLightbox
import ReactFsLightbox from 'fslightbox-react'

const FsLightbox = ReactFsLightbox.default
  ? ReactFsLightbox.default
  : ReactFsLightbox

const Index = () => {
  const socket = useRef(null)
  const dispatch = useDispatch()
  const activeUsers = useSelector((state) => state.setting.setting.active_users)
  const handlePosts = useSelector((state) => state.setting.setting.handle_posts)

  const token = Cookies.get('token');
  const userStatus = Cookies.get('userStatus');
  const loged_user = Cookies.get('userId');
  const userId = Cookies.get('userId');
  const [loadContent, setLoadContent] = useState(true)
  const [lives, setLives] = useState([])
  const [importantPosts, setImportantPosts] = useState([])
  const [posts, setPosts] = useState([])
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: false,
    skip: !hasMore || isLoading
  })

  const [imageController, setImageController] = useState({
    toggler: false,
    slide: 1,
  })

  useEffect(() => {
    function handleScroll() {
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {

        setTimeout(() => {
          setLoadContent(false)
        }, 2000)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    let cancel = false

    async function fetchData() {
      try {
        const response = await api.post(`/api/chat/getActiveFriends`)

        if (!cancel) {
          const data = response.data
          dispatch(set_active_users(data))
        }
      } catch (error) {
        console.error('Failed to fetch actives users data:', error)
      }
    }

    fetchData()

    return () => {
      cancel = true
    }
  }, [])

  useEffect(() => {
    let cancel = false

    async function fetchData() {
      try {
        const response = await api.get(`/api/livePosts`)

        if (!cancel) {
          const data = response.data
          setLives(data.posts)
        }
      } catch (error) {
        console.error('Failed to fetch live posts:', error)
      }
    }

    fetchData()

    return () => {
      cancel = true
    }
  }, [])

  useEffect(() => {
    let cancel = false

    async function getImportantPosts() {
      try {
        const response = await api.get(`/api/importantPosts`)

        if (!cancel) {
          const data = response.data
          setImportantPosts(data.important_posts)
        }
      } catch (error) {
        console.error('Failed to fetch important posts:', error)
      }
    }

    getImportantPosts()

    return () => {
      cancel = true
    }
  }, [handlePosts])

  const loadingRef = useRef(false)

  const fetchPostsData = useCallback(async (resetPage = false) => {
    if (loadingRef.current) return
    loadingRef.current = true
    setIsLoading(true)
    const currentPage = resetPage ? 1 : page
    try {
      const response = await api.post(`/api/postsList`, {
        page: currentPage,
        limit: 2
      })
      const data = response.data
      if (resetPage) {
        setPosts(data)
        setPage(2)
        setHasMore(true)
      } else {
        if (data.length === 0) {
          setHasMore(false)
          return
        } else {
          setPosts(prev => {
            const newPosts = data.filter(post => !prev.some(prevPost => prevPost.id === post.id))
            return [...prev, ...newPosts]
          })
          setPage(currentPage + 1)
        }
      }
    } catch (error) {
      console.error('Failed to fetch posts data:', error)
    } finally {
      setIsLoading(false)
      loadingRef.current = false
    }
  }, [page])

  const handleRefreshAndScrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    fetchPostsData(true)
  }
  useEffect(() => {
    const btnFeed = document.getElementById('feed');
  
    const handleClick = (e) => {
      e.preventDefault();
      btnFeed.innerHTML += `<span class="ms-2 spinner-border spinner-border-sm" role="status"></span>`;

      fetchPostsData(true).finally(() => {
        
        const spinner = btnFeed.querySelector('.spinner-border');
        if (spinner) {
          spinner.remove();
        }
      });
    };
  
    if (btnFeed) {
      btnFeed.addEventListener('click', handleClick);
    }
  
    return () => {
      if (btnFeed) {
        btnFeed.removeEventListener('click', handleClick);
      }
    };
  }, []);

  useEffect(() => {
    fetchPostsData(true)
  }, [])

  useEffect(() => {
    fetchPostsData(true)
  }, [handlePosts])

  useLayoutEffect(() => {
    if (inView && hasMore && !loadingRef.current) {
      fetchPostsData(false)
    }
  }, [inView, hasMore])

  useEffect(() => {
    socket.current = io(process.env.REACT_APP_API_SOCKET_URL, {
      transports: ['polling', 'websocket'],
    })

    socket.current.on('connect', () => {
      socket.current.emit('register', 'newsFeed')
    })

    return () => {
      if (socket.current) {
        socket.current.disconnect()
      }
    }
  }, [])

  useEffect(() => {
    const handleNewLive = ({ live }) => {
      console.log('nova live', live)

      setLives([...lives, live])
    }

    if (socket.current) {
      socket.current.on('newLive', handleNewLive)
    }

    return () => {
      if (socket.current) {
        socket.current.off('newLive', handleNewLive)
      }
    }
  }, [])

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      const [entry] = entries
      if (entry.isIntersecting && hasMore && !loadingRef.current) {
        fetchPostsData(false)
      }
    }, { threshold: 0.1 })

    if (ref.current) {
      observer.observe(ref.current)
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current)
      }
    }
  }, [hasMore, fetchPostsData])

  return (
    <>
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      <div id='content-page' className='content-inner'>

      <SorteioInviteModal userId={userId} />

      <SorteioWinners userId={userId} />

        <FsLightbox
          toggler={imageController.toggler}
          sources={[user5, boyImg, busImg, img11, mountain, pizza]}
          slide={imageController.slide}
        />
        <UserFirstAccessCheck />
        <Container>
          <Row className='gx-4'>
            <Col lg={8}>
              <div id='content'>
                <Row>
                  <Col sm={12}>
                    <CreatePost className='card-block card-stretch card-height' />
                  </Col>
                </Row>

                {
                  importantPosts.map(importantPost => (
                    <div key={`important_post_${importantPost.id}`}>
                      {
                        importantPost.quiz_id ? (
                          <QuizPost user_answer={importantPost.user_answer} important={importantPost.important} group_name={importantPost.group_name} user_id={importantPost.user_id} post_id={importantPost.id} user_name={importantPost.user_name} user_image={importantPost.user_image} type={importantPost.image ? 'image' : importantPost.video ? 'video' : 'text'} media={importantPost.image ?? importantPost.video} post_time={importantPost.created_at} text={importantPost.text} privacy={importantPost.privacy} group_id={importantPost.group_id} is_admin={false} user_reaction={importantPost.user_reaction} reactions={importantPost.reaction_counter} share_id={importantPost.share_id} share_posts={importantPost.sharePosts} view_count={importantPost.view_count} options={importantPost.quiz_options} right_answer={importantPost.quiz_right_answer} />
                        ) : importantPost.poll_id ? (
                          <PollPost important={importantPost.important} poll_id={importantPost.poll_id} group_name={importantPost.group_name} user_id={importantPost.user_id} post_id={importantPost.id} user_name={importantPost.user_name} user_image={importantPost.user_image} type={importantPost.image ? 'image' : importantPost.video ? 'video' : 'text'} media={importantPost.image ?? importantPost.video} post_time={importantPost.created_at} text={importantPost.text} privacy={importantPost.privacy} group_id={importantPost.group_id} is_admin={false} user_reaction={importantPost.user_reaction} reactions={importantPost.reaction_counter} share_id={importantPost.share_id} share_posts={importantPost.sharePosts} view_count={importantPost.view_count} />
                        ) : (
                          <Post important={importantPost.important} group_name={importantPost.group_name} user_id={importantPost.user_id} post_id={importantPost.id} user_name={importantPost.user_name} user_image={importantPost.user_image} type={importantPost.image ? 'image' : importantPost.video ? 'video' : 'text'} media={importantPost.image ?? importantPost.video} post_time={importantPost.created_at} text={importantPost.text} privacy={importantPost.privacy} group_id={importantPost.group_id} is_admin={false} user_reaction={importantPost.user_reaction} reactions={importantPost.reaction_counter} share_id={importantPost.share_id} share_posts={importantPost.sharePosts} view_count={importantPost.view_count} />
                        )
                      }
                    </div>
                  ))
                }

                {
                  lives.map(live => (
                    <LivePost key={`live_post_${live.id}`} event_id={live.event_id} user_name={live.user_name} user_image={live.user_image} live_iframe={live.live_iframe} live_name={live.live_name} post_time={live.created_at} privacy={live.privacy} post_id={live.id} user_reaction={live.user_reaction} reactions={live.reaction_counter} is_live={true} />
                  ))
                }

                {
                  posts.map((post, index) => (
                    <div ref={index === posts.length - 1 ? ref : null} key={`post_${post.id}`}>
                      {
                        post.live_id && !post.event_id ? (
                          <LivePost user_name={post.user_name} user_image={post.user_image} live_iframe={post.live_iframe} live_name={post.live_name} post_time={post.created_at} privacy={post.privacy} post_id={post.id} user_reaction={post.user_reaction} reactions={post.reaction_counter} share_id={post.share_id} share_posts={post.sharePosts} />
                        ) : post.event_id ? (
                          <EventPost event_id={post.event_id} event_place={post.event_place} event_external_link={post.event_external_link} event_start_date={post.event_start_date} event_end_date={post.event_end_date} event_name={post.event_name} user_id={post.user_id} post_id={post.id} user_name={post.user_name} user_image={post.user_image} type={post.event_image ? 'image' : 'text'} media={post.event_image} post_time={post.created_at} text={post.event_description} privacy={post.privacy} group_id={post.group_id} is_admin={false} user_reaction={post.user_reaction} reactions={post.reaction_counter} share_id={post.share_id} share_posts={post.sharePosts} />
                        ) : post.poll_id ? (
                          <PollPost poll_id={post.poll_id} group_name={post.group_name} user_id={post.user_id} post_id={post.id} user_name={post.user_name} user_image={post.user_image} type={post.image ? 'image' : post.video ? 'video' : 'text'} media={post.image ?? post.video} post_time={post.created_at} text={post.text} privacy={post.privacy} group_id={post.group_id} is_admin={false} user_reaction={post.user_reaction} reactions={post.reaction_counter} share_id={post.share_id} share_posts={post.sharePosts} view_count={post.view_count} />
                        ) : post.quiz_id ? (
                          <QuizPost user_answer={post.user_answer} group_name={post.group_name} user_id={post.user_id} post_id={post.id} user_name={post.user_name} user_image={post.user_image} type={post.image ? 'image' : post.video ? 'video' : 'text'} media={post.image ?? post.video} post_time={post.created_at} text={post.text} privacy={post.privacy} group_id={post.group_id} is_admin={false} user_reaction={post.user_reaction} reactions={post.reaction_counter} share_id={post.share_id} share_posts={post.sharePosts} view_count={post.view_count} options={post.quiz_options} right_answer={post.quiz_right_answer} />
                        ) : (
                          <Post group_name={post.group_name} user_id={post.user_id} post_id={post.id} user_name={post.user_name} user_image={post.user_image} type={post.image ? 'image' : post.video ? 'video' : 'text'} media={post.image ?? post.video} post_time={post.created_at} text={post.text} privacy={post.privacy} group_id={post.group_id} is_admin={false} user_reaction={post.user_reaction} reactions={post.reaction_counter} share_id={post.share_id} share_posts={post.sharePosts} view_count={post.view_count} />
                        )
                      }
                    </div>
                  ))
                }

                {isLoading && <div className='col-sm-12 text-center'>
                  <img src={loader} alt='loader' style={{ height: '100px' }} />
                </div>}
              </div>

              {!hasMore && (
                <div className='d-flex flex-column gap-1 text-center'>
                  <p className='text-primary'>
                    Por enquanto isso é tudo!
                  </p>
                  <a>O que há de novo?</a>
                  <a className='btn btn-sm text-primary border-0' onClick={handleRefreshAndScrollTop}>
                    <span className="material-symbols-outlined">
                      sync
                    </span>
                  </a>
                </div>
              )}
            </Col>

            <Col lg={4}>
              <Card>
                <div className='card-header d-flex justify-content-between'>
                  <div className='header-title'>
                    <h4 className='card-title text-capitalize'>usuários ativos</h4>
                  </div>
                </div>

                <Card.Body className='pt-0'>
                  <ul className='list-inline m-0 p-0' style={{ overflowY: 'auto', maxHeight: '300px' }}>
                    {activeUsers?.map(activeUser => (
                      <li className='d-flex align-items-center gap-3 mb-3' key={`active_user_${activeUser.id}`}>
                        <img
                          src={activeUser.user_about.image_profile ?? userGenericImage}
                          alt='story-img'
                          className='avatar-60 avatar-borderd object-cover avatar-rounded img-fluid d-inline-block'
                        />
                        <div>
                          <h5
                            style={{
                              maxWidth: '13rem',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              position: 'relative',
                              display: 'inline-block',
                            }}
                          >
                            <span
                              style={{
                                width: '100%',
                                display: 'inline-block',
                                transition: 'transform 2s ease',
                                position: 'relative',
                              }}
                              onMouseEnter={(e) => {
                                const maxWidth = 13 * 16; // Converte '13rem' para pixels (1rem = 16px padrão)
                                const textWidth = e.target.scrollWidth; // Largura real do texto
                                if (textWidth > maxWidth) {
                                  e.target.style.transform = `translateX(${maxWidth - textWidth}px)`; // Desliza para mostrar o texto oculto
                                }
                              }}
                              onMouseLeave={(e) => {
                                e.target.style.transform = 'translateX(0)'; // Retorna à posição inicial
                              }}
                            >
                              {activeUser.name}
                            </span>
                          </h5>
                          {activeUser.chat_status == 'online' ? (
                            <span className='profile-status-online'></span>
                          ) : (
                            <span className='profile-status-offline'></span>
                          )}
                          <small className='text-capitalize d-block'>
                            {activeUser.chat_status == 'online' ? 'Online' : 'Ocupado'}
                          </small>
                        </div>
                      </li>
                    ))}
                  </ul>
                </Card.Body>

              </Card>

              {/* <div className='fixed-suggestion mb-0 mb-lg-4'>
                <Card>
                  <div className='card-header d-flex justify-content-between'>
                    <div className='header-title'>
                      <h4 className='card-title'>Suggestions for you</h4>
                    </div>
                    <small className='fw-500 text-capitalize'>See all</small>
                  </div>
                  <Card.Body className='pt-0'>
                    <ul className='list-inline m-0 p-0'>
                      <li className='mb-3'>
                        <div className='d-flex align-items-center gap-2 justify-content-between'>
                          <div className='d-flex align-items-center gap-3'>
                            <img
                              src={user14}
                              alt='story-img'
                              className='avatar-60 avatar-borderd object-cover avatar-rounded img-fluid d-inline-block'
                            />
                            <div>
                              <h5>Annette Black</h5>
                              <div className='d-flex align-items-center justify-content-between gap-2'>
                              </div>
                              <small className='text-capitalize'>
                                Followed by dribbble + 2 more
                              </small>
                            </div>
                          </div>
                          <div className='d-flex align-items-center flex-shrink-0 gap-2'>
                            <button className='btn btn-primary-subtle p-1 lh-1'>
                              <i className='material-symbols-outlined font-size-14'>
                                add
                              </i>
                            </button>
                            <button className='btn btn-danger-subtle p-1 lh-1'>
                              <i className='material-symbols-outlined font-size-14'>
                                close
                              </i>
                            </button>
                          </div>
                        </div>
                      </li>
                      <li className='mb-3'>
                        <div className='d-flex align-items-center gap-2 justify-content-between'>
                          <div className='d-flex align-items-center gap-3'>
                            <img
                              src={user15}
                              alt='story-img'
                              className='avatar-60 avatar-borderd object-cover avatar-rounded img-fluid d-inline-block'
                            />
                            <div>
                              <div className='d-flex align-items-center justify-content-between gap-2'>
                                <div>
                                  <h5>Christopher Plessis</h5>
                                  <small className='text-capitalize'>
                                    Followed by dribbble + 2 more
                                  </small>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='d-flex align-items-center flex-shrink-0 gap-2'>
                            <button className='btn btn-primary-subtle p-1 lh-1'>
                              <i className='material-symbols-outlined font-size-14'>
                                add
                              </i>
                            </button>
                            <button className='btn btn-danger-subtle p-1 lh-1'>
                              <i className='material-symbols-outlined font-size-14'>
                                close
                              </i>
                            </button>
                          </div>
                        </div>
                      </li>
                      <li className=''>
                        <div className='d-flex align-items-center gap-2 justify-content-between'>
                          <div className='d-flex align-items-center gap-3'>
                            <img
                              src={user16}
                              alt='story-img'
                              className='avatar-60 avatar-borderd object-cover avatar-rounded img-fluid d-inline-block'
                            />
                            <div>
                              <div className='d-flex align-items-center justify-content-between gap-2'>
                                <h5>Ellyse Perry</h5>
                              </div>
                              <small className='text-capitalize'>
                                Followed by dribbble + 2 more
                              </small>
                            </div>
                          </div>
                          <div className='d-flex align-items-center flex-shrink-0 gap-2'>
                            <button className='btn btn-primary-subtle p-1 lh-1'>
                              <i className='material-symbols-outlined font-size-14'>
                                add
                              </i>
                            </button>
                            <button className='btn btn-danger-subtle p-1 lh-1'>
                              <i className='material-symbols-outlined font-size-14'>
                                close
                              </i>
                            </button>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </Card.Body>
                </Card>

                <Card>
                  <div className='card-header d-flex justify-content-between'>
                    <div className='header-title'>
                      <h4 className='card-title'>Latest Activities</h4>
                    </div>
                  </div>
                  <Card.Body className='pt-0'>
                    <ul className='list-inline m-0 p-0'>
                      <li className='d-flex mb-3 pb-3 border-bottom'>
                        <img
                          src={user9}
                          alt='story-img'
                          className='avatar-32 avatar-rounded object-cover img-fluid'
                        />
                        <div className='stories-data ms-3'>
                          <h6 className='font-size-14'>
                            Esther Howard{' '}
                            <span className='fw-normal text-body'>
                              reacted comment in to your
                            </span>{' '}
                            <span className='fw-normal'>post.</span>
                          </h6>
                          <small className='text-capitalize'>19 min ago</small>
                        </div>
                      </li>
                      <li className='d-flex mb-3 pb-3 border-bottom'>
                        <img
                          src={user10}
                          alt='story-img'
                          className='avatar-32 avatar-rounded object-cover img-fluid'
                        />
                        <div className='stories-data ms-3'>
                          <h6 className='font-size-14'>
                            Monty Carlo{' '}
                            <span className='text-body fw-normal'>
                              followed you.
                            </span>
                          </h6>
                          <small className='text-capitalize'>7 day ago</small>
                        </div>
                      </li>
                      <li className='d-flex mb-3 pb-3 border-bottom'>
                        <img
                          src={user6}
                          alt='story-img'
                          className='avatar-32 avatar-rounded object-cover img-fluid'
                        />
                        <div className='stories-data ms-3'>
                          <h6 className='font-size-14'>
                            Pete Sariya
                            <span className='text-body fw-normal'> Voted for</span>{' '}
                            Combination of colors from your brand palette
                          </h6>
                          <small className='text-capitalize'>1 month ago</small>
                        </div>
                      </li>
                      <li className='d-flex'>
                        <img
                          src={user11}
                          alt='story-img'
                          className='avatar-32 avatar-rounded object-cover img-fluid'
                        />
                        <div className='stories-data ms-3'>
                          <h6 className='font-size-14'>
                            Dima Davydov{' '}
                            <span className='text-body fw-normal'>
                              replied to your comment
                            </span>
                          </h6>
                          <small className='text-capitalize'>1 month ago</small>
                        </div>
                      </li>
                    </ul>
                  </Card.Body>
                </Card>
              </div> */}
            </Col>

          </Row>
        </Container>
      </div>
    </>
  )
}

export default Index
