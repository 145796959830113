import React, { Fragment, useState, useEffect } from 'react';
import { Dropdown, Form, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import api from '../services/api';
import Cookies from 'js-cookie'
import user1 from "../assets/images/page-img/19.jpg";

const SearchModal = () => {
    const [users, setUsers] = useState([]);
    const [search, setSearch] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const token = Cookies.get('token');


    useEffect(() => {
        loadRandomUsers();
    }, []);

    const loadRandomUsers = async () => {
        try {
            const response = await api.get('/api/users/search', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setUsers(response.data);
        } catch (error) {
            console.error('Error fetching random users:', error);
        }
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (search.trim()) {
                fetchUsers(search.trim());
            }
        }, 300);

        return () => clearTimeout(delayDebounceFn);
    }, [search]);

    const fetchUsers = async (query) => {
        setIsSearching(true);
        try {
            const response = await api.get(`/api/users/search?query=${encodeURIComponent(query)}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setUsers(response.data);
        } catch (error) {
            console.error('Error searching users:', error);
        }
        setIsSearching(false);
    };

    return (
        <Fragment>
            <Dropdown.Menu  className={`search-modal-custom sub-drop`}
                                    aria-labelledby="group-drop"
                                    data-bs-popper="static">
                <div className="search-modal-content">
                    <div className="px-3 py-2">
                        <div className="d-lg-flex">
                            <Form className="searchbox d-flex">
                                <Link className="search-link" to="/">
                                    {/* SVG omitted for brevity */}
                                </Link>
                                <Form.Control
                                    type="text"
                                    className="text search-input form-control bg-light-subtle"
                                    placeholder="Busque por pessoas ou grupos..."
                                    value={search}
                                    onChange={(e) => {
                                        setSearch(e.target.value);
                                    }}
                                />
                            </Form>
                        </div>
                    </div>
                    <Modal.Body className="p-0">
                        <div className="item-header-scroll">
                            {isSearching ? (
                                <div className='d-flex align-items-center search-hover py-2 px-3'>Buscando...</div>
                            ) : (
                                users.map(user => (
                                    <div className="d-flex align-items-center search-hover py-2 px-3" key={user.id}>
                                        <div className="flex-shrink-0">
                                            <img
                                                src={user.image_profile || user1}
                                                className="align-self-center img-fluid avatar-50 rounded-pill"
                                                alt={user.name}
                                            />
                                        </div>
                                        <div className="d-flex ms-3 w-100 justify-content-between">
                                            <div className="d-flex flex-column">
                                                <Link to={`/users/profile/${user.id}`} className="h6">{user.name}</Link>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    </Modal.Body>

                </div>
            </Dropdown.Menu>
        </Fragment>
    );
}

export default SearchModal;
