import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import Cookies from 'js-cookie';
import api from '../../services/api';
import { toast } from 'react-toastify';
import ProfileHeader from "../../components/profile-header";
import { FaSearch } from 'react-icons/fa';
import { useInView } from 'react-intersection-observer';
import loader from "../../assets/images/page-img/page-load-loader.gif";
import { debounce } from 'lodash';

const FriendOfFriendList = () => {
  const token = Cookies.get('token');
  let { id } = useParams();
  const [User, setUser] = useState([]);
  const [Friends, setFriends] = useState([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { ref, inView } = useInView({
    threshold: 0.1,
  });

  const fetchData = useCallback(async (resetPage = false) => {
    setIsLoading(true);
    const currentPage = resetPage ? 1 : page;
    try {
      const response = await api.post(`/api/friends/showFriendsOfFriends`, {
        id,
        token,
        search,
        page: currentPage,
        limit: 10
      });
      const data = response.data.friends.data;
      const UserProfile = response.data.user;

      if (resetPage) {
        setFriends(data);
        setUser(UserProfile);
        setPage(2);
      } else {
        setFriends(prev => [...prev, ...data]);
        setUser(UserProfile);
        setPage(currentPage + 1);
      }
      setHasMore(data.length === 10);
    } catch (error) {
      console.error('Failed to fetch user data:', error);
    } finally {
      setIsLoading(false);
    }
  }, [search, page, id, token]);

  const handleSearchChange = useCallback(debounce((newSearch) => {
    setSearch(newSearch);
    fetchData(true);
  }, 300), []);

  useEffect(() => {
    fetchData(true);
  }, [search]);

  // useEffect(() => {
  //   if (inView && hasMore && !isLoading) {
  //     fetchData(false);
  //   }
  // }, [inView, hasMore, isLoading]);

  const filteredFriends = useMemo(() => {
    return search ? Friends.filter(f => f.name.toLowerCase().includes(search.toLowerCase())) : Friends;
  }, [search, Friends]);

  return (
    <>
      <div id="content-page" className="content-inner">
        <Container>
            <Row>
            <Col sm='12'>
              <div className='card-header d-flex justify-content-between px-0 pb-4 mx-5'>
                <div className='header-title'>
                  <h5 className='fw-semibold'>{`Amigos de ${User.name}`}</h5>
                </div>
              </div>
            <div className="mb-4 mt-4">
              <Form.Group className="input-group">
                <span className="input-group-text" id="basic-addon1"><FaSearch /></span>
                <Form.Control
                  placeholder='Pesquisar'
                  aria-label='pesquisar'
                  aria-describedby='search'
                  onChange={(e) => handleSearchChange(e.target.value)}
                />
              </Form.Group>
            </div>
            <ul className="request-list list-inline m-0 p-0">
              {filteredFriends.map((friend, index) => (
               <li className="d-flex align-items-center justify-content-between mb-4" md={12} key={index} ref={index === filteredFriends.length - 1 ? ref : null}>
                  <div className="d-flex align-items-center">
                    <div className="user-img img-fluid flex-shrink-0">
                      <img
                        src={friend.user_about.image_profile}
                        alt="story-img"
                        className="rounded-circle avatar-40"
                      />
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <Link to={`/users/profile/${friend.id}`} >
                        {friend.name}
                      </Link>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            {isLoading && <div className="col-sm-12 text-center">
              <img src={loader} alt="loader" style={{ height: "100px" }} />
            </div>}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default FriendOfFriendList;
