import React, { useState, useEffect } from 'react';
import { OrganizationChart } from 'primereact/organizationchart';
import api from '../../services/api';
import placeholderIMG from '../../assets/images/placeholder.png';
import { Container, Row, Col, Card, } from 'react-bootstrap';
import './style-organorama.css'
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
// import 'primereact/resources/themes/saga-blue/theme.css';
// import 'primeicons/primeicons.css';

const OrganogramaIndex = () => {
    const [data, setData] = useState(null);
    const [zoom, setZoom] = useState(1);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [isDragging, setIsDragging] = useState(false);
    const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });

    const buildHierarchy = (positions) => {
        const userMap = new Map();
        let rootNodes = [];

        positions.forEach((position) => {
            position.users.forEach((user) => {
                const node = {
                    label: user.name,
                    type: 'person',
                    className: 'bg-primary',
                    style: {
                        color: 'white',
                        borderRadius: '8px',
                        padding: '1.5rem',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    },
                    data: {
                        image: user.image_profile || null,
                        name: user.name,
                        title: position.name,
                        userId: user.id,
                    },
                    children: [],
                };

                userMap.set(user.id, node);
            });
        });

        positions.forEach((position) => {
            position.users.forEach((user) => {
                const userNode = userMap.get(user.id);

                if (position.parent_position_id) {
                    const parentPosition = positions.find(
                        (p) => p.id === position.parent_position_id
                    );

                    if (parentPosition) {
                        parentPosition.users.forEach((parentUser) => {
                            const parentNode = userMap.get(parentUser.id);
                            if (parentNode) {
                                parentNode.children.push(userNode);
                            }
                        });
                    }
                } else {
                    rootNodes.push(userNode);
                }
            });
        });

        return rootNodes;
    };

    useEffect(() => {
        api.get('/api/organograma/index')
            .then(response => {
                const hierarchy = buildHierarchy(response.data);
                setData(hierarchy);
            })
            .catch(error => {
                console.error('Erro ao carregar o organograma', error);
            });
    }, []);

    const zoomIn = () => setZoom((prev) => Math.min(prev + 0.1, 2));
    const zoomOut = () => setZoom((prev) => Math.max(prev - 0.1, 0.5));
    const resetZoom = () => setZoom(1);

    const handleMouseDown = (e) => {
        setIsDragging(true);
        setStartPosition({ x: e.clientX - position.x, y: e.clientY - position.y });
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        setPosition({ x: e.clientX - startPosition.x, y: e.clientY - startPosition.y });
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleTouchStart = (e) => {
        setIsDragging(true);
        const touch = e.touches[0];
        setStartPosition({ x: touch.clientX - position.x, y: touch.clientY - position.y });
    };

    const handleTouchMove = (e) => {
        if (!isDragging) return;
        const touch = e.touches[0];
        setPosition({ x: touch.clientX - startPosition.x, y: touch.clientY - startPosition.y });
    };

    const handleTouchEnd = () => {
        setIsDragging(false);
    };

    const nodeTemplate = (node) => {
        if (node.type === 'person') {
            return (
                <div className="d-flex flex-column align-items-center card-person">
                    <a
                        href={`/users/profile/${node.data.userId}`}
                        className="btn btn-primary btn-sm rounded-circle"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            alt={node.data.name}
                            src={node.data.image ? node.data.image : placeholderIMG}
                            style={{ width: "4rem", height: "4rem", borderRadius: "50%" }}
                        />
                    </a>
                    <span className="fw-bold fs-6">{node.data.name}</span>
                    <span className="text-white-75">{node.data.title}</span>
                </div>
            );
        }
        return node.label;
    };

    return (
        <>
            <div id="content-page" className="content-inner">
                <Container>
                    <Row>
                        <Col sm="12">
                            <Card className="position-relative inner-page-bg bg-primary p-5">
                                <div className="inner-page-title">
                                    <h3 className="text-white">Organograma</h3>
                                </div>
                            </Card>
                        </Col>
                        <Col sm="12" lg="12">
                            <Card>
                                <Card.Body>
                                    <div className="border border-1 p-3 rounded rounded-2 overflow-hidden">
                                        <div className="d-flex justify-content-end">
                                            <ButtonGroup>
                                                <Button className='d-flex align-items-center' onClick={zoomIn}>
                                                    <span className="material-symbols-outlined">
                                                        zoom_in
                                                    </span>
                                                </Button>
                                                <Button className='d-flex align-items-center' onClick={resetZoom}>
                                                    <span className="material-symbols-outlined">
                                                        restart_alt
                                                    </span>
                                                </Button>
                                                <Button className='d-flex align-items-center' onClick={zoomOut}>
                                                    <span className="material-symbols-outlined">
                                                        zoom_out
                                                    </span>
                                                </Button>
                                            </ButtonGroup>
                                        </div>
                                        <div
                                            className="organization-chart-container"
                                            style={{
                                                transform: `translate(${position.x}px, ${position.y}px) scale(${zoom})`,
                                                transformOrigin: "top center",
                                                cursor: isDragging ? "grabbing" : "grab",
                                                userSelect: "none",
                                            }}
                                            onMouseDown={handleMouseDown}
                                            onMouseMove={handleMouseMove}
                                            onMouseUp={handleMouseUp}
                                            onMouseLeave={handleMouseUp}
                                            onTouchStart={handleTouchStart}
                                            onTouchMove={handleTouchMove}
                                            onTouchEnd={handleTouchEnd}
                                        >
                                            {data ? (
                                                data.length > 0 ? (
                                                    <OrganizationChart value={data} nodeTemplate={nodeTemplate} />
                                                ) : (
                                                    'Nenhum dado disponível.'
                                                )
                                            ) : (
                                                'Carregando...'
                                            )}
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default OrganogramaIndex;
