import React, { useState, useEffect } from "react";
import { Row, Col, Form, Container, Button, Card } from "react-bootstrap";
import { useNavigate } from 'react-router-dom'
import { Link } from "react-router-dom";
import api from '../../services/api'
import { toast } from 'react-toastify'
import Select from 'react-select'

const PositionsCreate = () => {
    const history = useNavigate()
    const [name, setName] = useState('');
    const [parentPositionId, setParentPositionId] = useState(null);
    const [positions, setPositions] = useState([]);
    const customId = 'success-toast-id'

    useEffect(() => {
        let cancel = false

        async function fetchData() {

            await api.post(`api/positions/index`).then(res => {
                if (cancel) return

                let positionsData = []

                res.data.positions.forEach(position => {
                    positionsData.push({
                        value: position.id,
                        label: position.name
                    })
                })

                setPositions(positionsData)
            })
        }

        fetchData()

        return () => {
            cancel = true
        }
    }, [])

    const createPosition = async (e) => {
        e.preventDefault()

        if (name === '') {
            toast.error('Por favor preencha o campo de nome.', { autoClose: 3000 })
        } else {
            await api.post('/api/position/store', {
                name,
                parent_position_id: parentPositionId
            }).then(() => {
                setName('')
                setParentPositionId(null);
                toast.success('Cargo criado com sucesso!', { toastId: customId, autoClose: 3000, onClose: history('/positions/index') })
            })
        }
    }

    return (
        <>
            <div id='content-page' className='content-inner'>
                <Container>
                    <Row>
                        <Col sm="12">
                            <Card
                                className="position-relative inner-page-bg bg-primary p-5"
                            >
                                <div className="inner-page-title" style={{ display: "flex", justifyContent: "space-between", width: "-webkit-fill-available" }}>
                                    <h3 className="text-white">Cargos</h3>
                                    <Link to='/positions/index'>
                                        <Button><i className="icon material-symbols-outlined d-flex">
                                            arrow_back
                                        </i></Button>
                                    </Link>
                                </div>
                            </Card>
                        </Col>
                        <Col sm="12" lg="12">
                            <Col sm="12" lg="12">
                                <Card>
                                    <Card.Header className="d-flex justify-content-between">
                                        <div className="header-title">
                                            <h4 className="card-title">Criar cargo</h4>
                                        </div>
                                    </Card.Header>
                                    <Card.Body className="pt-0">
                                        <Form noValidate onSubmit={createPosition} className="row g-3">
                                            <Col md="6" className="">
                                                <Form.Label
                                                    className="form-label"
                                                    htmlFor="validationCustom01"
                                                >
                                                    Nome
                                                </Form.Label>
                                                <Form.Control className='input-group-edit' type='text' placeholder='Nome' value={name} onChange={(e) => setName(e.target.value)} aria-describedby="inputGroupPrepend" required />
                                                <Form.Control.Feedback>
                                                    Correto!
                                                </Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid">
                                                    Please choose a position name.
                                                </Form.Control.Feedback>
                                            </Col>
                                            <Col md="6" className="">
                                                <Form.Label
                                                    className="form-label"
                                                    htmlFor="validationCustom02"
                                                >
                                                    Cargo Pai
                                                </Form.Label>
                                                <Select
                                                    className='input-group-edit'
                                                    value={positions.find((pos) => pos.value === parentPositionId) || null}
                                                    onChange={(selectedOption) => setParentPositionId(selectedOption ? selectedOption.value : null)}
                                                    options={positions}
                                                    isClearable
                                                />
                                                <Form.Control.Feedback>
                                                    Correto!
                                                </Form.Control.Feedback>
                                            </Col>
                                            <div className="col-12">
                                                <Button type="submit" variant="primary">Criar</Button>
                                            </div>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default PositionsCreate