import React, { useEffect, useState } from "react";
import { Row, Col, Container, Dropdown, Button } from 'react-bootstrap'
import Card from '../../components/Card'
import { Link, useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import api from '../../services/api'
import { formatDistanceToNow, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { toast } from 'react-toastify'
import { useNotification } from '../../context/NotificationContext';
import loader from "../../assets/images/page-img/page-load-loader.gif";

const Notification = () => {

   const { notifications, allNotifications, isNewNotification, unreadCount, fetchNotification } = useNotification();
   const [isLoading, setIsLoading] = useState(false);
   const token = Cookies.get('token');
   const loged_user = Cookies.get('userId');
   const history = useNavigate();

   const readNotification = async (index, event) => {
      event.preventDefault();
      try {
         await api.post(`/api/notification/markAsRead`, { index, loged_user }, {
            headers: { Authorization: `Bearer ${token}` },
         })
         fetchNotification();
      } catch (error) {
         console.error('Failed to remove friend:', error);
      }
   };

   const readAllNotification = async (event) => {
      event.preventDefault();
      try {
         await api.post(`/api/notification/markAllAsRead`, { loged_user }, {
            headers: { Authorization: `Bearer ${token}` },
         })
         toast.info(`Notificações marcadas como lidas.`, { autoClose: 1000 });
         fetchNotification();
      } catch (error) {
         console.error('Failed to remove friend:', error);
      }
   };

   const deleteNotification = async (index, event) => {
      event.preventDefault();
      try {
         await api.post(`/api/notification/deleteNotification`, { index, loged_user }, {
            headers: { Authorization: `Bearer ${token}` },
         })
         toast.info(`Notificação deletada com sucesso.`, { autoClose: 1000 });
         fetchNotification();
      } catch (error) {
         console.error('Failed to remove friend:', error);
      }
   };

   const deleteAllNotification = async (event) => {
      event.preventDefault();
      try {
         await api.post(`/api/notification/deleteAll`, { loged_user }, {
            headers: { Authorization: `Bearer ${token}` },
         })
         toast.info(`Todas as notificações foram deletadas.`, { autoClose: 1000 });
         fetchNotification();
      } catch (error) {
         console.error('Failed to remove friend:', error);
      }
   };

   useEffect(() => {
      setIsLoading(true);
      fetchNotification().then(() => {
         setIsLoading(false);
      }).catch(() => {
         setIsLoading(false);
      });
   }, [fetchNotification]);

   const handleNotificationClick = async (notification) => {

      if (notification.url.includes('posts/post-detail/')) {
         const postId = notification.url.split('/')[2];

         try {
            const response = await api.get(`/api/posts/check/${postId}`);

            if (response.data.exists) {
               history(`/posts/post-detail/${postId}`)
            } else {
               toast.info('Esta publicação foi removida.', { autoClose: 3000 });
            }
         } catch (error) {
            if (error.response.status == 404) {
               toast.info('Esta publicação foi removida.', { autoClose: 3000 });
            } else {
               console.error('Erro ao verificar o post:', error);
            }
         }
      } else if (notification.url === '/friends/friend-request') {
         history(`/friends/friend-request`);
      } else if (notification.url === '/groups/groups') {
         history(`/groups/groups`);
      } else {
         toast.error('Tipo de notificação inválida', { autoClose: 3000 })
      }
   };

   return (
      <>
         <div id='content-page' className='content-inner'>
            <Container>
               <Row>
                  <Col sm="12">
                     <div className="card-header d-flex justify-content-between px-0 pb-4 mx-5">
                        <div className="header-title">
                           <h5 className="fw-semibold">Notificações</h5>
                        </div>
                        <div className="d-flex">
                           <div className="mx-2">
                              {unreadCount > 0 &&
                                 <Button className="d-flex" onClick={(e) => readAllNotification(e)}>
                                    <div className="d-none d-lg-block d-sm-block">
                                       Marcar todas como lidas
                                    </div>
                                    <span className="material-symbols-outlined mt-0 mb-0">
                                       done
                                    </span>
                                 </Button>
                              }
                           </div>
                           <div>
                              {allNotifications.length > 0 &&
                                 <Button className="d-flex" onClick={(e) => deleteAllNotification(e)}>
                                    <div className="d-none d-lg-block d-sm-block">
                                       Deletar todas notificações
                                    </div>
                                    <span className="material-symbols-outlined mt-0 mb-0">
                                       delete
                                    </span>
                                 </Button>
                              }
                           </div>
                        </div>
                     </div>
                  </Col>
                  <Col sm="12">
                     {isLoading ? (
                        <div className="col-sm-12 text-center">
                           <img src={loader} alt="loader" style={{ height: "100px" }} />
                        </div>
                     ) : allNotifications && allNotifications.length > 0 ? (
                        <div>
                           {allNotifications && allNotifications.map((notification, index) => (
                              <Card key={index}>
                                 <Card.Body>
                                    <ul className="notification-list m-0 p-0">
                                       <li className="d-flex align-items-center justify-content-between">
                                          <div className="user-img img-fluid">
                                             <img src={notification.image_profile} alt="story-img" className="rounded-circle avatar-40" />
                                          </div>
                                          <div className="w-100">
                                             <div className="d-flex justify-content-between">
                                                <div className="ms-3 cursor-pointer" onClick={() => handleNotificationClick(notification)}>
                                                   <h6>{notification.user_name} {" "}
                                                      <span className="text-body fw-normal text-lowercase">
                                                         {notification.message}
                                                      </span>
                                                   </h6>
                                                   <small className="mb-0"> {formatDistanceToNow(parseISO(notification.created_at), { addSuffix: true, locale: ptBR })}
                                                      {notification.read == false &&
                                                         <span className="bg-primary text-white notification-badge-read"></span>
                                                      }</small>
                                                </div>
                                             </div>
                                          </div>
                                          <div className="d-flex align-items-center">
                                             <div className="card-header-toolbar d-flex align-items-center">
                                                <Dropdown>
                                                   <div>
                                                      <Dropdown.Toggle as="span" className="material-symbols-outlined">
                                                         more_horiz
                                                      </Dropdown.Toggle>
                                                   </div>
                                                   <Dropdown.Menu className="dropdown-menu-right">
                                                      {notification.read == false ? (
                                                         <Dropdown.Item onClick={(e) => readNotification(notification.id, e)} className='dropdown-item d-flex align-items-center'>
                                                            <span className="material-symbols-outlined me-2 md-18">
                                                               done
                                                            </span>
                                                            Marcar como lida
                                                         </Dropdown.Item>
                                                      ) :
                                                         ''
                                                      }
                                                      <Dropdown.Item onClick={(e) => deleteNotification(notification.id, e)} className='dropdown-item d-flex align-items-center'>
                                                         <span className="material-symbols-outlined me-2 md-18">
                                                            delete
                                                         </span>
                                                         Apagar
                                                      </Dropdown.Item>
                                                   </Dropdown.Menu>
                                                </Dropdown>
                                             </div>
                                          </div>
                                       </li>
                                    </ul>
                                 </Card.Body>
                              </Card>
                           ))}
                        </div>
                     ) : (
                        <div className="text-center">
                           <p>Não há notificações no momento.</p>
                        </div>
                     )}
                  </Col>
               </Row>
            </Container>
         </div>
      </>
   )
}

export default Notification