import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { Container, Row, Col, OverlayTrigger, Button, Form, Modal, Tooltip } from 'react-bootstrap'
import { useParams, Link } from 'react-router-dom'
import Cookies from 'js-cookie'
import api from '../../services/api'
import { toast } from 'react-toastify'
import { FaSearch } from 'react-icons/fa'
import { useInView } from 'react-intersection-observer'
import loader from '../../assets/images/page-img/page-load-loader.gif'
import { debounce } from 'lodash'
import io from 'socket.io-client'
import AnimationWarningLottie from '../../components/AnimationWarningDeleteConfim/AnimationWarningLottie'

const MembersList = () => {
  const token = Cookies.get('token')
  const loged_user = Cookies.get('userId')
  const [members, setMembers] = useState([])
  const [admins, setAdmins] = useState([])
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [isAdm, setIsAdm] = useState(false)
  const [isCreator, setIsCreator] = useState(false)
  const [modalShow, setModalShow] = useState(false)
  const [memberToRemove, setMemberToRemove] = useState(null)
  let { id } = useParams()
  const { ref, inView } = useInView({
    threshold: 0.1,
  })

  const socket = io(process.env.REACT_APP_API_SOCKET_URL, {
    autoConnect: true,
    withCredentials: true,
  })

  const fetchData = useCallback(async (resetPage = false) => {
    setIsLoading(true)
    const currentPage = resetPage ? 1 : page
    try {
      const response = await api.post(`/api/groupMembers/${id}`, {
        loged_user,
        token,
        search,
        page: currentPage,
        limit: 10
      })
      const data = response.data

      if (resetPage) {
        setMembers(data.members.data)
        setAdmins(data.admins_list)
        setIsAdm(data.is_adm)
        setIsCreator(data.is_creator)
        setPage(2)
      } else {
        setMembers(prev => [...prev, ...data.members.data])
        setIsAdm(data.is_adm)
        setIsCreator(data.is_creator)
        setPage(currentPage + 1)
      }
      setHasMore(data.length === 10)
    } catch (error) {
      console.error('Failed to fetch user data:', error)
    } finally {
      setIsLoading(false)
    }
  }, [search, page, loged_user, token])

  const handleSearchChange = useCallback(debounce((newSearch) => {
    setSearch(newSearch)
    fetchData(true)
  }, 300), [])

  useEffect(() => {
    fetchData(true)
  }, [search])

  useEffect(() => {
    if (inView && hasMore && !isLoading) {
      fetchData(false)
    }
  }, [inView, hasMore, isLoading])

  const removeGroupMember = async (member_id) => {
    try {
      await api.post(`/api/removeGroupMember`, { member_id, group_id: id }, {
        headers: { Authorization: `Bearer ${token}` },
      }).then(() => {
        toast.success('Membro removido com sucesso!', { autoClose: 1000 })
        setMembers(prev => prev.filter(m => m.id !== member_id))
        setModalShow(false)
      })
    } catch (error) {
      toast.error('Erro ao remover membro do grupo', { autoClose: 1000 })
      setModalShow(false)
      console.error('Failed to remove member:', error)
    }
  }

  const toggleMemberAdmin = async (member_id) => {
    try {
        await api.post(`/api/toggleMemberAdmin`, { member_id, group_id: id }, {
          headers: { Authorization: `Bearer ${token}` },
        }).then((res) => {
          setAdmins(res.data.admins_list)
        })
      } catch (error) {
        toast.error('Erro ao alterar membro do grupo', { autoClose: 1000 })
        console.error('Failed to update group member:', error)
      }
  }

  function DeleteMemberConfirmationModal(props) {
    return (
        <Modal
            {...props}
            aria-labelledby='contained-modal-title-vcenter'
            centered
        >
            <Modal.Header id='modalHeader' closeButton>
                <div id='divModalTitle'>
                    <Modal.Title id='modalTitle'>
                        Exclusão de membro
                    </Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body id='modalBody'>
                <div className='d-flex justify-content-center'>
                    <AnimationWarningLottie />
                </div>
                <div className='d-flex justify-content-center'>
                    <p>
                        Tem certeza de que deseja remover esse membro ?
                    </p>
                </div>
            </Modal.Body>
            <Modal.Footer id='modalFooter'>
                <Button id='yesButton' onClick={() => removeGroupMember(memberToRemove)}>Sim</Button>
                <Button className='btn-danger' id='noButton' onClick={props.onHide}>Não</Button>
            </Modal.Footer>
        </Modal>
    )
  }

  const filteredMembers = useMemo(() => {
    return search ? members.filter(f => f.name.toLowerCase().includes(search.toLowerCase())) : members
  }, [search, members])

  return (
    <>
      <div id='content-page' className='content-inner'>
        <DeleteMemberConfirmationModal
            show={modalShow}
            onHide={() => setModalShow(false)}
        />

        <Container>
          <Row>
            <Col sm='12'>
              <div className='card-header d-flex justify-content-between px-0 pb-4 mx-5'>
                <div className='header-title'>
                  <h5 className='fw-semibold'>Membros</h5>
                </div>
              </div>
            </Col>
            <div className='mb-4 mt-4'>
              <Form.Group className='input-group'>
                <span className='input-group-text' id='basic-addon1'><FaSearch /></span>
                <Form.Control
                  placeholder='Pesquisar'
                  aria-label='Pesquisar'
                  aria-describedby='search'
                  onChange={(e) => handleSearchChange(e.target.value)}
                />
              </Form.Group>
            </div>
            <ul className='request-list list-inline m-0 p-0'>
              {filteredMembers.map((member, index) => (
                <li className='d-flex align-items-center justify-content-between' key={index}>
                  <div className='d-flex align-items-center'>
                    <div className='user-img img-fluid flex-shrink-0'>
                      <img
                        src={member.user_about.image_profile}
                        alt='story-img'
                        className='rounded-circle avatar-40'
                      />
                    </div>
                    <div className='flex-grow-1 ms-3'>
                      <Link to={`/users/profile/${member.user_id}`} >
                        {member.name}
                      </Link>
                    </div>
                  </div>
                  <div className='d-flex align-items-center mt-2 mt-md-0'>
                    <div className='confirm-click-btn'>
                      {
                        isAdm && loged_user != member.id && !admins.includes(member.id) ? (
                          <OverlayTrigger placement="right" overlay={<Tooltip>Tornar admin</Tooltip>}>
                            <Button className='me-2 btn btn-success-subtle rounded confirm-btn p-1 lh-1' onClick={() => toggleMemberAdmin(member.id)}>
                              <i className='material-symbols-outlined font-size-14'>
                                admin_panel_settings
                              </i>
                            </Button>
                          </OverlayTrigger>
                        ) : isAdm && loged_user != member.id && admins.includes(member.id) ? (
                          <OverlayTrigger placement="right" overlay={<Tooltip>Remover admin</Tooltip>}>
                            <Button className='me-2 btn btn-danger-subtle rounded confirm-btn p-1 lh-1' onClick={() => toggleMemberAdmin(member.id)}>
                              <i className='material-symbols-outlined font-size-14'>
                                admin_panel_settings
                              </i>
                            </Button>
                          </OverlayTrigger>
                        ) : ''
                      }
                    </div>
                    <div className='confirm-click-btn'>
                      <OverlayTrigger placement="right" overlay={<Tooltip>Remover membro</Tooltip>}>
                        <Button className='me-2 btn btn-danger-subtle rounded confirm-btn p-1 lh-1'
                          onClick={() => {
                            setMemberToRemove(member.id)
                            setModalShow(true)
                          }}
                        >
                          <i className='material-symbols-outlined font-size-14'>
                            close
                          </i>
                        </Button>
                      </OverlayTrigger>
                    </div>
                  </div>
                </li>
              ))}
            </ul> 
            {isLoading && <div className='col-sm-12 text-center'>
              <img src={loader} alt='loader' style={{ height: '100px' }} />
            </div>}
          </Row>
        </Container>
      </div>
    </>
  )
}

export default MembersList
