import React, { useState } from "react";
import { Row, Col, Form, Container, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import api from "../../services/api"; // Para fazer requisições à API

// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { Navigation, Autoplay } from "swiper/modules";

// img
import atmoLogo from '../../assets/images/atmo logo.png'
import atmoLogo2 from '../../assets/images/atmo_midia_logo_branco.png'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as SettingSelector from "../../store/setting/selectors";
import { useSelector } from "react-redux";

// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

const Recoverpw = () => {
  const appName = useSelector(SettingSelector.app_name);

  // Estado para armazenar o email
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [showSpinner, setShowSpinner] = useState(false);

  // Função de envio do formulário
  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowSpinner(true);


    try {
      await api.post('/api/password/email', { email }).then(() => {
        toast.success('Sucesso, se houver uma conta com estes dados, você receberá uma email com o passo a passo.', {
          autoClose: 2000,
        });
      });
      setEmail('');
    } catch (err) {
      toast.success('Sucesso, se houver uma conta com estes dados, você receberá uma email com o passo a passo.', {
        autoClose: 2000,
      })
    } finally {
      setShowSpinner(false);
    }
  };

  return (
    <>
      <section className="sign-in-page">
        <Container fluid>
          <Row className="align-items-center">
            <Col md={6} className="overflow-hidden position-relative">
              <div className="bg-primary w-100 h-100 position-absolute top-0 bottom-0 start-0 end-0"></div>
              <div className="container-inside z-1">
                <div className="main-circle circle-small"></div>
                <div className="main-circle circle-medium"></div>
                <div className="main-circle circle-large"></div>
                <div className="main-circle circle-xlarge"></div>
                <div className="main-circle circle-xxlarge"></div>
              </div>
              <div className="sign-in-detail container-inside-top">
                <Swiper
                  className="list-inline m-0 p-0 "
                  spaceBetween={30}
                  centeredSlides={true}
                  loop={true}
                  autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                  }}
                >
                  <ul className="swiper-wrapper list-inline m-0 p-0 ">
                    <SwiperSlide>
                      <img
                        style={{ width: "250px" }}
                        src={atmoLogo2}
                        className="img-fluid mb-2"
                        alt="images"
                      />
                      <p className="mt-3 text-white fw-semibold">
                        Bem-vindo à plataforma que conecta talentos, transforma a comunicação e fortalece sua empresa.
                      </p>
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        style={{ width: "250px" }}
                        src={atmoLogo2}
                        className="img-fluid mb-2"
                        alt="images"
                      />
                      <p className="mt-3 text-white fw-semibold">
                        Conecte-se com sua equipe, colabore em tempo real e compartilhe ideias que impulsionam a produtividade.
                      </p>
                    </SwiperSlide>
                    <SwiperSlide>
                      <img style={{ width: "250px" }}
                        src={atmoLogo2}
                        className="img-fluid mb-2"
                        alt="images"
                      />
                      <p className="mt-3 text-white fw-semibold">
                        A rede social corporativa que une equipes, projetos e pessoas em um só lugar.
                      </p>
                    </SwiperSlide>
                  </ul>
                </Swiper>
              </div>
            </Col>
            <Col md={6}>
              <div className="sign-in-from text-center">
                <Link
                  to="/auth/sign-in"
                  className="d-inline-flex align-items-center justify-content-center gap-2"
                >
                  <img style={{ width: 50 }} src={atmoLogo} alt='Logo Atmo' ></img>
                  <h2 className="logo-title" data-setting="app_name">
                    {appName}
                  </h2>
                </Link>
                <p className="mt-3 font-size-16">
                  Digite seu endereço de e-mail e lhe enviaremos um e-mail com
                  instruções para redefinir sua senha.
                </p>

                {message && <p className="text-success">{message}</p>}
                {error && <p className="text-danger">{error}</p>}

                <Form className="mt-5" onSubmit={handleSubmit}>
                  <Form.Group className="form-group text-start mb-0">
                    <h6 className="form-label fw-bold">Endereço de email</h6>
                    <Form.Control
                      type="email"
                      className="form-control mb-0"
                      placeholder="Digite seu email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>
                  <Button
                    variant="primary"
                    type="submit"
                    className="btn btn-primary mt-4 fw-semibold text-uppercase w-100"
                    disabled={showSpinner}
                  >
                    {showSpinner ? <div className="spinner-border spinner-border-sm" role="status"></div> : <div className='fw-semibold'>Redefinir senha</div>}
                  </Button>
                  <h6 className="mt-5">
                    <Link to={"/auth/sign-in"}>Voltar para login</Link>
                  </h6>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Recoverpw;
