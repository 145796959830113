import React, { useEffect, useState, useMemo } from "react";
import { Container, Row, Col, Card, Button, Modal, Form, Dropdown, ButtonGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import api from "../../services/api";
import { toast } from "react-toastify";
import { FaSearch } from "react-icons/fa";
import AnimationWarningLottie from "../../components/AnimationWarningDeleteConfim/AnimationWarningLottie";
import ActiveSorteio from "./ativo";
import moment from 'moment';



const SorteioIndex = () => {
  const navigate = useNavigate();
  const history = useNavigate();
  const [sorteio, setSorteios] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [sorteioIdToDelete, setSorteioIdToDelete] = useState("");
  const [sorteioNameToDelete, setSorteioNameToDelete] = useState("");
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [sorteioPerPage] = useState(10);
  const indexOfLastSorteio = currentPage * sorteioPerPage;
  const indexOfFirstSorteio = indexOfLastSorteio - sorteioPerPage;
  const currentSorteios = sorteio.slice(indexOfFirstSorteio, indexOfLastSorteio);
  const nPages = Math.ceil(sorteio.length / sorteioPerPage);
  const [activeSorteio, setActiveSorteio] = useState(null);


  const Pagination = ({ nPages, currentPage, setCurrentPage }) => {
    if (nPages <= 1) return null;

    const range = (start, end) =>
      Array(end - start + 1)
        .fill()
        .map((_, idx) => start + idx);

    let pageNumbers = [];
    if (currentPage <= 3) {
      pageNumbers = [...range(1, Math.min(5, nPages))];
      if (nPages > 5) pageNumbers.push("...", nPages);
    } else if (currentPage > 3 && currentPage < nPages - 2) {
      pageNumbers = [1, "...", ...range(currentPage - 1, currentPage + 1), "...", nPages];
    } else {
      pageNumbers = [1, "...", ...range(nPages - 4, nPages)];
    }

    return (
      <nav className="d-flex justify-content-center align-items-center">
        <ul className="pagination mb-4">
          <li className="page-item">
            <Link className="page-link" onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}>
              <i className="icon material-symbols-outlined d-flex">arrow_back</i>
            </Link>
          </li>
          {pageNumbers.map((number, index) => (
            <li key={index} className={`page-item ${currentPage === number ? "active" : ""}`}>
              <Link className="page-link" onClick={() => typeof number === "number" && setCurrentPage(number)}>
                {number}
              </Link>
            </li>
          ))}
          <li className="page-item">
            <Link className="page-link" onClick={() => setCurrentPage((prev) => Math.min(prev + 1, nPages))}>
              <i className="icon material-symbols-outlined d-flex">arrow_forward</i>
            </Link>
          </li>
        </ul>
      </nav>
    );
  };

  const getSorteios = async () => {
    try {
      const response = await api.get("/api/sorteio/index");
      setSorteios(response.data.sorteios);
      console.log(response.data.sorteios)

    } catch (error) {
      console.error("Erro ao carregar sorteios:", error);
      toast.error("Erro ao carregar dados.", { autoClose: 3000 });
    }
  };

  useEffect(() => {
    const fetchActiveSorteio = async () => {
      try {
        const response = await api.get('/api/sorteios/active');
        setActiveSorteio(response.data.sorteio);
      } catch (error) {
        console.error('Erro ao carregar sorteio ativo:', error);
      }
    };

    fetchActiveSorteio();
  }, []);

  const showEditSorteioPage = (sorteio_id) => {
    navigate(`/sorteio/edit/${sorteio_id}`);
  };

  const showBlacklistSorteioPage = (sorteio_id) => {
    navigate(`/sorteio/blacklist/${sorteio_id}`);
  };

  const showCreateSorteioPage = () => {
    navigate(`/sorteio/create`);
  };

  const showDeleteSorteioConfirmationModal = (sorteio_id, sorteio_title) => {
    setModalShow(true);
    setSorteioIdToDelete(sorteio_id);
    setSorteioNameToDelete(sorteio_title);
  };

  const deleteSorteio = async (sorteio_id) => {
    try {
      await api.delete(`/api/sorteio/destroy/${sorteio_id}`);
      getSorteios();
      setModalShow(false);
      toast.success("Sorteio deletado com sucesso!", { autoClose: 3000 });
    } catch (error) {
      console.error("Erro ao excluir o sorteio:", error);
      toast.error("Erro ao excluir o sorteio.", { autoClose: 3000 });
    }
  };

  function DeleteSorteioConfirmationModal(props) {
    return (
      <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title>Excluir Sorteio</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center">
            <AnimationWarningLottie />
          </div>
          <div className="d-flex justify-content-center">
            <p>Você tem certeza de que deseja remover o sorteio "{props.sorteioName}"?</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => deleteSorteio(props.sorteio_id)}>Sim</Button>
          <Button onClick={props.onHide}>Não</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  useEffect(() => {
    getSorteios();
  }, []);

  const filteredSorteios = useMemo(() => {
    const lowerSearch = search.toLowerCase();
    return search === ""
      ? currentSorteios
      : sorteio.filter(
        (sorteio) =>
          sorteio.name.toLowerCase().includes(lowerSearch) ||
          sorteio.id.toString().toLowerCase().includes(lowerSearch)
      );
  }, [search, sorteio, currentSorteios]);

  const formatPercentage = (winners, invitationsLength) => {
    if (!invitationsLength || invitationsLength === 0) {
      return '';
    }

    const result = (winners / invitationsLength) * 100;

    return isFinite(result) ? result.toFixed(2) + '%' : '';
  };

  const downloadSorteioReport = async (sorteio_id) => {
    try {
        const response = await api({
            url: `/api/sorteioReport`, // Endpoint para sorteios
            method: 'post',
            data: { sorteio_id },
            responseType: 'blob',
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `sorteio_${sorteio_id}_report.xlsx`); // Nome do arquivo gerado
        document.body.appendChild(link);
        link.click();
        link.remove();

        toast.success('Relatório de sorteio gerado com sucesso!', { autoClose: 1000 });
    } catch (err) {
        toast.error('Erro ao gerar relatório de sorteio', { autoClose: 1000 });
        console.error(err);
    }
};



  return (
    <div id="content-page" className="content-inner">
      <Container>
        <DeleteSorteioConfirmationModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          sorteio_id={sorteioIdToDelete}
          sorteioName={sorteioNameToDelete}
        />
        <Row>
          <Col sm="12">
            <Card className="position-relative inner-page-bg bg-primary p-5">
              <div className="inner-page-title">
                <h3 className="text-white">Sorteios</h3>
              </div>
            </Card>
          </Col>
          <Col sm="12" lg="12">
            <Card>
              <Card.Body>
                <div className="mb-4 mt-4">

                  {activeSorteio ? (
                    <ActiveSorteio sorteio_id={activeSorteio.id} />
                  ) : (
                    <p>Nenhum sorteio ativo no momento.</p>
                  )}


                  <Form.Group className="input-group">
                    <span className="input-group-text">
                      <FaSearch />
                    </span>
                    <Form.Control
                      placeholder="Pesquisar"
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <Button onClick={showCreateSorteioPage}>
                      <i className="icon material-symbols-outlined d-flex">post_add</i>
                    </Button>
                  </Form.Group>
                </div>
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Sorteio</th>
                        <th className="text-center">Nº Vencedores</th>
                        <th className="text-center">Nº Participantes</th>
                        <th className="text-center">% Individual</th>
                        <th className="text-center">Aceita inscrições</th>
                        <th className="text-center">Realizado</th>
                        <th scope="col">Ativo</th>
                        <th >Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredSorteios.map((sorteio, index) => (
                        <tr key={index}>
                          <th>{sorteio.id}</th>
                          <td>{sorteio.title}</td>
                          <td className="text-center">{sorteio.winners}</td>
                          <td className="text-center">{sorteio.invitations.length}</td>
                          <td className="text-center">{formatPercentage(sorteio.winners, sorteio.invitations.length)}</td>
                          <td className="text-center">{sorteio.convite_user ? "Sim" : "Não"}</td>
                          <td className="text-center">{sorteio.sorteio_date ? moment(sorteio.sorteio_date).format('DD/MM/YYYY') : '-'}</td>
                          <td className="text-center">{sorteio.active ? "Sim" : "Não"}</td>
                          <td className="d-flex gap-2">
                            <Dropdown as={ButtonGroup}>
                              <Dropdown.Toggle variant="primary" id={`dropdown-${sorteio.id}`}>
                                Ações
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item onClick={() => showEditSorteioPage(sorteio.id)}>
                                  <i className="material-symbols-outlined align-middle me-2">mode_edit</i>
                                  <span className="align-middle">Editar</span>
                                </Dropdown.Item>

                                <Dropdown.Item onClick={() => showDeleteSorteioConfirmationModal(sorteio.id, sorteio.title)}>
                                  <i className="material-symbols-outlined align-middle me-2">delete</i>
                                  <span className="align-middle">Excluir</span>
                                </Dropdown.Item>

                                <Dropdown.Item onClick={() => history(`/sorteio/chart/${sorteio.id}`)}>
                                  <i className='material-symbols-outlined align-middle me-2'> monitoring </i>
                                  <span className="align-middle">Gráficos</span>
                                </Dropdown.Item>

                                <Dropdown.Item onClick={() => downloadSorteioReport(sorteio.id)}>
                                  <i className='material-symbols-outlined align-middle me-2'> download </i>
                                  <span className="align-middle">Relatório</span>
                                </Dropdown.Item>

                                <Dropdown.Item onClick={() => showBlacklistSorteioPage(sorteio.id)} className="text-danger">
                                  <i className="material-symbols-outlined align-middle me-2">person_off</i>
                                  <span className="align-middle">Adicionar à Blacklist</span>
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Card.Body>
            </Card>
            <Pagination nPages={nPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SorteioIndex;
