import React, { useState } from 'react'
import { useNavigate } from "react-router-dom"
import api from '../../services/api'
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap"
import './style-add.css'

import defaultImg from "../../assets/images/page-img/gi-1.jpg"
import defaultHeader from "../../assets/images/page-img/profile-bg7.jpg"

const GroupAdd = () => {
  const [groupName, setGroupName] = useState('')
  const [groupDescription, setGroupDescription] = useState('')
  const [groupImage, setGroupImage] = useState(defaultImg)
  const [headerImage, setHeaderImage] = useState(defaultHeader)
  const [imageFile, setImageFile] = useState(null)
  const [headerFile, setHeaderFile] = useState(null)
  const [groupType, setGroupType] = useState('2')
  const [showSpinner, setShowSpinner] = useState(false);
  const navigate = useNavigate()

  const handleImageChange = (e) => {
    const file = e.target.files[0]
    if (file) {
      setImageFile(file)
      const reader = new FileReader()
      reader.onloadend = () => {
        setGroupImage(reader.result)
      }
      reader.readAsDataURL(file)
    }
  }

  const handleHeaderChange = (e) => {
    const file = e.target.files[0]
    if (file) {
      setHeaderFile(file)
      const reader = new FileReader()
      reader.onloadend = () => {
        setHeaderImage(reader.result)
      }
      reader.readAsDataURL(file)
    }
  }

  const handleGroupTypeChange = (e) => {
    setGroupType(e.target.value)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setShowSpinner(true);

    const formData = new FormData()
    formData.append('name', groupName)
    formData.append('description', groupDescription)
    formData.append('type', groupType)
    if (imageFile) {
      formData.append('image', imageFile)
    }
    if (headerFile) {
      formData.append('background', headerFile)
    }

    try {
      console.log(headerFile)
      const response = await api.post('/api/group/store', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      navigate(`/groups/group-detail/${response.data.group.id}`)

    } catch (error) {
      console.error('Error creating group:', error)
    } finally {
      setShowSpinner(false);
    }
  }

  const groupTypeDescription = groupType === '1'
    ? "Somente membros podem ver quem está no grupo e o que é publicado nele."
    : "Qualquer pessoa pode ver quem está no grupo e o que é publicado nele."

  return (
    <>
      <div id="content-page" className="content-inner">
        <Container>
          <Row>
            <Col sm="12">
              <div className="d-flex flex-column align-items-center justify-content-center mb-3 flex-wrap">
                <div className="group-header position-relative mb-3">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleHeaderChange}
                    style={{ display: 'none' }}
                    id="upload-header"
                  />
                  <label htmlFor="upload-header" className="position-relative">
                    <img
                      src={headerImage}
                      alt="Header"
                      className="img-fluid"
                      id="header-image"
                    />
                    <div className="overlay"></div>
                    <i className="material-symbols-outlined edit-icon">edit</i>
                  </label>
                </div>

                <div className="group-info position-relative mb-3">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    style={{ display: 'none' }}
                    id="upload-image"
                  />
                  <label htmlFor="upload-image" className="position-relative">
                    <img
                      className="rounded-circle img-fluid"
                      src={groupImage}
                      alt="Group"
                      id="profile-image"
                    />
                    <div className="overlay rounded-circle"></div>
                    <i className="material-symbols-outlined edit-icon">edit</i>
                  </label>
                </div>
              </div>
            </Col>
            <Col lg="12">
              <Card>
                <Card.Body>
                  <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="text"
                        placeholder="Nome do grupo"
                        value={groupName}
                        onChange={(e) => setGroupName(e.target.value)}
                        required
                        style={{ border: 'none', fontSize: '2rem', fontWeight: 'bold' }}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Descrição do grupo"
                        value={groupDescription}
                        onChange={(e) => setGroupDescription(e.target.value)}
                        required
                        style={{ border: 'none', fontSize: '1rem', fontWeight: 'bold' }}
                      />
                    </Form.Group>

                    {/* Input de Tipo de Grupo como Select */}
                    <Form.Group className="mb-3">
                      <Form.Select
                        value={groupType}
                        onChange={handleGroupTypeChange}
                        aria-label="Tipo de Grupo"
                      >
                        <option value="1">Privado</option>
                        <option value="2">Público</option>
                      </Form.Select>
                      <small className="text-muted">{groupTypeDescription}</small>
                    </Form.Group>

                    <Button
                      variant="primary"
                      type="submit"
                      className="w-100"
                      disabled={showSpinner}
                    >
                      Criar Grupo
                    </Button>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default GroupAdd
