import React, { useState, useEffect, useRef } from 'react';
import { Card, Button, Table, Modal, ListGroup } from 'react-bootstrap';
import api from '../../services/api';
import { toast } from 'react-toastify';
import { Fireworks } from 'fireworks-js';
import { useNavigate } from "react-router-dom";


const ActiveSorteio = ({ sorteio_id }) => {
    const [participants, setParticipants] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showTable, setShowTable] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [sorteio, setSorteio] = useState([]);
    const [winners, setWinners] = useState([]);
    const [error, setError] = useState('');
    const [isCurrentUserWinner, setIsCurrentUserWinner] = useState(false);
    const containerRef = useRef(null);
    const fireworksRef = useRef(null);

    useEffect(() => {
        if (showModal && containerRef.current) {
            const fireworks = new Fireworks(containerRef.current, {
                speed: 2,
                acceleration: 1.05,
                particles: 150,
                traceLength: 3,
                explosion: 5,
            });

            fireworks.start();
            fireworksRef.current = fireworks;

            // Parar os fogos de artifício após 5 segundos
            setTimeout(() => {
                fireworks.stop();
            }, 5000);
        }

        return () => {
            if (fireworksRef.current) {
                fireworksRef.current.stop();
            }
        };
    }, [showModal]);

    // Carregar participantes do sorteio
    useEffect(() => {
        const fetchParticipants = async () => {
            try {
                const response = await api.get(`/api/sorteios/${sorteio_id}/participants`);
                setParticipants(response.data.participants || []);
            } catch (error) {
                console.error('Erro ao carregar participantes:', error);
                toast.error('Erro ao carregar participantes.');
            }
        };

        fetchParticipants();
    }, [sorteio_id]);


    useEffect(() => {
        const fetchSorteio = async () => {
            try {
                const response = await api.get(`/api/sorteio/edit/${sorteio_id}`);

                if (response.data) {
                    setSorteio(response.data);

                } else {
                    toast.error("Erro ao carregar o sorteio.");
                }
            } catch (error) {
                console.error("Erro ao carregar o sorteio:", error);
                toast.error("Erro ao buscar dados do sorteio.");
            }
        };
        fetchSorteio();
        setShowTable(true)
    }, [sorteio_id]);


    const handleRealizarSorteio = async () => {

        setShowSpinner(true)

        try {
            const response = await api.post(`/api/sorteios/${sorteio_id}/realizar`);
            setWinners(response.data.winners);
            setShowModal(true)
            setShowTable(false)
            setShowSpinner(false)
            toast.success('Sorteio realizado com sucesso!', {
                autoClose: 3000
            });
        } catch (err) {
            setError('Erro ao realizar o sorteio.');
            toast.error('Erro ao realizar o sorteio.');
            setShowSpinner(false)
        }
    };

    const handleClose = () => setShowModal(false);

    return (

        <Card>
            {showTable ? (
                <>
                    <Card.Header>
                        <h2 className="card-title">Sorteio Ativo: {sorteio.id}</h2>
                        <h4 className="mb-0">{sorteio.title}</h4>
                        <p className="mt-0">{sorteio.descricao}</p>
                    </Card.Header>
                    <Card.Body>
                        <Table striped bordered hover className="mt-3">
                            <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th>Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                {participants.map((participant) => (
                                    <tr key={participant.id}>
                                        <td className='d-flex gap-3 align-items-center'>
                                            <img className='rounded-pill' style={{ width: '3rem', height: '3rem' }} src={participant.image_profile}></img>
                                            {participant.name}
                                        </td>
                                        <td>{participant.email}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>

                        <Button variant="primary" onClick={handleRealizarSorteio} disabled={showSpinner}>
                            {showSpinner ? <span className="spinner-border spinner-border-sm"></span> : 'Sortear'}
                        </Button>
                    </Card.Body>
                </>
            ) : ''
            }
            {/* Modal para exibir o vencedor */}
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton className=''>
                    <Modal.Title>
                        {isCurrentUserWinner ? 'Parabéns, você ganhou!' : 'Ganhadores do Sorteio: ' + sorteio.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div ref={containerRef} style={{ position: 'relative', width: '100%', height: '300px' }}>
                        {isCurrentUserWinner ? (
                            <>
                                <p>Parabéns você é um dos ganhadores do sorteio <strong>{sorteio.title}</strong> 🎉</p>

                                <p>Os ganhadores são:</p>
                                <ul>
                                    {winners.map((winner) => (
                                        <li key={winner.user_id}>
                                            <img className='rounded-pill m-2' style={{ width: '2.5rem', height: '2.5rem' }} src={winner.image_profile}></img>
                                            <a href={'/users/profile/' + winner.user_id}>{winner.name}</a>
                                        </li>
                                    ))}
                                </ul>
                            </>

                        ) : (
                            <>
                                <p>Os ganhadores são:</p>
                                <ul>
                                    {winners.map((winner) => (
                                        <li key={winner.user_id}>
                                            <img className='rounded-pill m-2' style={{ width: '2.5rem', height: '2.5rem' }} src={winner.image_profile}></img>
                                            <a href={'/users/profile/' + winner.user_id}>{winner.name}</a>
                                        </li>
                                    ))}
                                </ul>
                            </>
                        )}
                    </div>

                </Modal.Body>
                <Modal.Footer className=''>
                    <Button className='z-3' variant="secondary" onClick={handleClose}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>
        </Card>
    );
};

export default ActiveSorteio;
