import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col, Card, Button, Modal, Form } from "react-bootstrap";
import { useParams, Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import io from 'socket.io-client';
import { FaVideo, FaMicrophone, FaVideoSlash, FaMicrophoneSlash, FaCog, FaStop, FaPlay, FaPause, FaSpinner } from 'react-icons/fa'; // Icons
import api from '../../services/api'
import Cookies from 'js-cookie'
import LiveChat from '../../components/LiveChat'
import { toast } from 'react-toastify'
import { useLocation, useNavigate } from "react-router-dom";

const LiveBrowser = () => {
    const { id } = useParams();
    const [isStreaming, setIsStreaming] = useState(false);
    const [videoDevices, setVideoDevices] = useState([]);
    const [audioDevices, setAudioDevices] = useState([]);
    const [selectedVideoDevice, setSelectedVideoDevice] = useState('');
    const [selectedAudioDevice, setSelectedAudioDevice] = useState('');
    const [audioEnabled, setAudioEnabled] = useState(true); // Estado para controlar o áudio
    const [videoEnabled, setVideoEnabled] = useState(true); // Estado para controlar o vídeo
    const videoRef = useRef(null);
    const socket = useRef(null);
    const mediaRecorderRef = useRef(null); // MediaRecorder para vídeo e áudio
    const streamRef = useRef(null); // Armazenar o stream
    const [showModal, setShowModal] = useState(false); // Controla a exibição da modal
    const [showControls, setShowControls] = useState(false); // Estado para controlar a exibição dos botões
    const [streamings, setStreamings] = useState([])
    const logged_user = Cookies.get('userId')
    const [post, setPost] = useState([]);
    const [isLoading, setIsLoading] = useState(true)
    const [isScreenSharing, setIsScreenSharing] = useState(false);
    const [showExitModal, setShowExitModal] = useState(false);
    const [navigateTo, setNavigateTo] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();

    const handleNavigate = (path) => {
        if (path !== location.pathname) {
            setNavigateTo(path);
            setShowExitModal(true);
        }
    };

    const handleCancel = () => {
        setShowExitModal(false);
    };

    const handleConfirmNavigation = async () => {
        setIsLoading(true)
        if (streamRef.current) {
            const stream = streamRef.current;  // Obtém o stream atual
            const tracks = stream.getTracks(); // Obtém todos os tracks do stream

            tracks.forEach(track => track.stop()); // Para todos os tracks (vídeo e áudio)
            streamRef.current = null; // Limpa o stream
        }
        if (isStreaming) {
            await api.post(`/api/streaming/endLiveBrowser`, {
                streaming_id: id,
                logged_user,
            })
        }
        setShowExitModal(false);
        setIsLoading(false)

        if (navigateTo) {
            navigate(navigateTo);
        }
    };



    useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.preventDefault();
            event.returnValue = '';
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);


    const getStreamingBydId = async () => {
        setIsLoading(true)
        try {
            const response = await api.post(`/api/streaming/getStreamingById/${id}`)
            setStreamings(response.data.streamings)
            setIsLoading(false)
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        getStreamingBydId()
    }, [])



    // Conectar ao servidor WebRTC via Socket.IO
    useEffect(() => {
        socket.current = io(process.env.REACT_APP_WEBRTC_SOCKET_URL);

        socket.current.on('connect', () => {
            console.log('Conectado ao servidor webRTC');
        });

        socket.current.on('disconnect', () => {
            console.log('Desconectado do servidor');
        });

        return () => {
            if (socket.current) {
                socket.current.disconnect();
            }
        };
    }, []);

    useEffect(() => {
        const getDevices = async () => {
            const devices = await navigator.mediaDevices.enumerateDevices();
            const videoDevices = devices.filter(device => device.kind === 'videoinput');
            const audioDevices = devices.filter(device => device.kind === 'audioinput');
            setVideoDevices(videoDevices);
            setAudioDevices(audioDevices);

            if (videoDevices.length > 0 && audioDevices.length > 0) {
                setSelectedVideoDevice(videoDevices[0].deviceId);
                setSelectedAudioDevice(audioDevices[0].deviceId);
                startPreview(videoDevices[0].deviceId, audioDevices[0].deviceId); // Exibir o vídeo de preview
            }
        };

        getDevices();
    }, []);

    // Função para exibir o vídeo de preview
    const startPreview = async (videoDeviceId, audioDeviceId) => {
        setIsLoading(true)
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                video: { deviceId: videoDeviceId },
                audio: { deviceId: audioDeviceId }
            });

            streamRef.current = stream; // Armazenar o stream
            videoRef.current.srcObject = stream; // Exibir o vídeo de preview
            setIsLoading(false)

        } catch (error) {
            console.error("Erro ao acessar a webcam:", error);
            toast.error('Erro ao acessar a webcam:' + error, { autoClose: 1000 })
        }
    };

    // Função para enviar os dados de vídeo para o servidor
    const sendToServer = (data) => {
        if (data) {
            socket.current.emit('video-data', {
                videoData: data,
                rtmpsLink: streamings.rtmps_link,
                streamKey: streamings.stream_key
            });
        }
    };
    // Função para iniciar o stream com dispositivos selecionados (webcam)
    const startStream = async () => {
        setIsLoading(true)
        const response = await api.post(`/api/streaming/startLiveBrowser`, {
            streaming_id: id,
            logged_user,
        })

        setPost(response.data.post)
        setIsLoading(false)

        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                video: { deviceId: selectedVideoDevice },
                audio: { deviceId: selectedAudioDevice }
            });

            videoRef.current.srcObject = stream;
            setIsStreaming(true);
            socket.current.emit('start-transmission', {
                rtmpsLink: streamings.rtmps_link,
                streamKey: streamings.stream_key
            });

            // MediaRecorder para webcam
            mediaRecorderRef.current = new MediaRecorder(stream);
            mediaRecorderRef.current.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    sendToServer(event.data);
                }
            };
            mediaRecorderRef.current.start(1000); // Enviar dados a cada 1000ms

            streamRef.current = stream; // Armazenar o stream
        } catch (error) {
            console.error("Erro ao iniciar o stream:", error);
            toast.error('Erro ao iniciar o stream:' + error, { autoClose: 1000 })

        }
    };

    const startScreenShare = async () => {
        stopStream(); // Parar o stream antes de compartilhar a tela
        try {

            if (mediaRecorderRef.current) {
                mediaRecorderRef.current.stop(); // Parar a gravação da webcam
            }
            // Solicitar captura da tela
            const screenStream = await navigator.mediaDevices.getDisplayMedia({
                video: true, // Captura o vídeo da tela
            });

            // Solicitar captura de áudio da webcam
            const audioStream = await navigator.mediaDevices.getUserMedia({
                audio: { deviceId: selectedAudioDevice }, // Captura o áudio do microfone
            });

            // Combina o vídeo da tela com o áudio da webcam em um único stream
            const combinedStream = new MediaStream([
                ...screenStream.getTracks(), // Adiciona os vídeos da tela
                ...audioStream.getTracks()   // Adiciona o áudio do microfone
            ]);

            videoRef.current.srcObject = combinedStream; // Atualiza o vídeo de preview
            streamRef.current = combinedStream;          // Armazena o novo stream combinado
            setIsStreaming(true); // Atualiza o estado
            setIsScreenSharing(true); // Atualiza o estado

            socket.current.emit('start-transmission', {
                rtmpsLink: streamings.rtmps_link,
                streamKey: streamings.stream_key
            });// Marca como streaming

            // MediaRecorder para capturar o combinado (áudio + vídeo)
            mediaRecorderRef.current = new MediaRecorder(combinedStream);
            mediaRecorderRef.current.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    sendToServer(event.data);
                }
            };
            mediaRecorderRef.current.start(1000); // Enviar dados a cada 1000ms
        } catch (error) {
            console.error("Erro ao compartilhar a tela:", error);
            toast.error('Erro ao compartilhar a tela', { autoClose: 1000 });
        }
    };


    const stopScreenShare = async () => {
        const currentStream = videoRef.current.srcObject;

        const screenTrack = currentStream.getVideoTracks()[0];
        screenTrack.stop();

        const stream = await navigator.mediaDevices.getUserMedia({
            video: { deviceId: selectedVideoDevice },
            audio: { deviceId: selectedAudioDevice }
        });

        videoRef.current.srcObject = stream;

        setIsScreenSharing(false); // Atualiza o estado

        stopStream();
        startStream();

        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();  // Parar o MediaRecorder
        }
    };

    // Função para parar o stream
    const stopStream = () => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop(); // Parar a gravação da webcam
        }

        const stream = videoRef.current.srcObject;
        const tracks = stream.getTracks();
        tracks.forEach(track => track.stop());
        videoRef.current.srcObject = null;

        setIsStreaming(false);
        socket.current.emit('stop-transmission'); // Parar a transmissão no back-end
        startPreview(selectedVideoDevice, selectedAudioDevice);
    };

    // Função para trocar o dispositivo de vídeo (câmera)
    const changeVideoDevice = async (newVideoDeviceId) => {
        try {
            // Obtém o track de vídeo atual
            const videoTrack = streamRef.current.getVideoTracks()[0];

            // Cria um novo stream com o novo dispositivo de vídeo
            const newStream = await navigator.mediaDevices.getUserMedia({
                video: { deviceId: newVideoDeviceId }, // Novo dispositivo de vídeo
                audio: { deviceId: selectedAudioDevice } // Mantém o áudio atual
            });

            // Substitui o track de vídeo no stream atual
            const newVideoTrack = newStream.getVideoTracks()[0];
            videoTrack.stop(); // Para o track antigo de vídeo
            streamRef.current.removeTrack(videoTrack); // Remove o track de vídeo antigo
            streamRef.current.addTrack(newVideoTrack); // Adiciona o novo track de vídeo

            // Atualiza o videoRef com o novo stream
            videoRef.current.srcObject = streamRef.current;
        } catch (error) {
            console.error("Erro ao trocar a câmera:", error);
            toast.error('Erro ao trocar a câmera:' + error, { autoClose: 1000 })

        }
    };

    const changeAudioDevice = async (newAudioDeviceId) => {
        try {
            // Obtém o track de áudio atual
            const audioTrack = streamRef.current.getAudioTracks()[0];

            // Cria um novo stream com o novo dispositivo de áudio
            const newStream = await navigator.mediaDevices.getUserMedia({
                video: { deviceId: selectedVideoDevice }, // Mantém o vídeo atual
                audio: { deviceId: newAudioDeviceId } // Novo dispositivo de áudio
            });

            // Substitui o track de áudio no stream atual
            const newAudioTrack = newStream.getAudioTracks()[0];
            audioTrack.stop(); // Para o track antigo de áudio
            streamRef.current.removeTrack(audioTrack); // Remove o track de áudio antigo
            streamRef.current.addTrack(newAudioTrack); // Adiciona o novo track de áudio

            // Atualiza o stream com o novo áudio
            streamRef.current = streamRef.current;
        } catch (error) {
            console.error("Erro ao trocar o microfone:", error);
            toast.error('Erro ao trocar o microfone' + error, { autoClose: 1000 })

        }
    };


    // Função para salvar configurações na modal
    const saveSettings = () => {
        changeVideoDevice(selectedVideoDevice); // Troca a câmera
        changeAudioDevice(selectedAudioDevice); // Troca o microfone
        setShowModal(false); // Fecha a modal
        if (isStreaming) {
            stopStream();
            startStream();
        }
        toast.success('Dispositivos alterados com sucesso!', { autoClose: 1000 })
    };

    // Função para desligar webcam
    const toggleCamera = () => {
        const videoTrack = streamRef.current.getVideoTracks()[0];
        videoTrack.enabled = !videoTrack.enabled;
        setVideoEnabled(videoTrack.enabled); // Atualiza o estado do vídeo
    };

    // Função para desligar microfone
    const toggleAudio = () => {
        const audioTrack = streamRef.current.getAudioTracks()[0];
        audioTrack.enabled = !audioTrack.enabled;
        setAudioEnabled(audioTrack.enabled); // Atualiza o estado do áudio
    };

    const sendVOD = async () => {
        setIsLoading(true)
        await api.post(`/api/streaming/endLiveBrowser`, {
            streaming_id: id,
            logged_user,
        })
        stopStream(); // Para o stream e encerra a transmissão
        toast.success('Transmissão finalizada e enviada para o VOD!', { autoClose: 1000 })
        setIsLoading(false)
    };

    return (
        <>
            <ToastContainer />
            <div id='content-page' className='content-inner'>
                <Container fluid="xl">
                    <Row className='gx-4'>
                        <Col sm='12'>
                            <Card className='position-relative inner-page-bg bg-primary p-5'>
                                <div className='inner-page-title' style={{ display: 'flex', justifyContent: 'space-between', width: '-webkit-fill-available' }}>
                                    <h3 className='text-white'>{streamings.nome}</h3>
                                    <Button onClick={() => handleNavigate('/')}><i className='icon material-symbols-outlined d-flex'>
                                        arrow_back
                                    </i></Button>
                                </div>
                            </Card>
                        </Col>
                        <div className='div-modal-showPost-content' style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                            <div className='div-content-modal-onmobile' style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div
                                    style={{
                                        position: "relative",
                                        width: "100%",
                                        height: "auto",
                                        display: "inline-block"
                                    }}
                                    onMouseEnter={() => setShowControls(true)} // Exibe os botões ao passar o mouse
                                    onMouseLeave={() => setShowControls(false)} // Esconde os botões quando o mouse sai
                                >
                                    <video ref={videoRef} autoPlay muted width="100%" height="auto" className="border rounded" />


                                    {/* Navbar/Control Buttons */}
                                    {showControls && (
                                        <div className="video-controls" style={{
                                            position: 'absolute',
                                            bottom: '50px',
                                            left: '50%',
                                            transform: 'translateX(-50%)',
                                            zIndex: 10,
                                        }}>
                                            <Button style={{ marginRight: '10px' }} variant="secondary" onClick={() => setShowModal(true)}><FaCog /></Button>
                                            <Button style={{ marginRight: '10px' }} variant="danger" onClick={toggleCamera} className="mr-2">
                                                {videoEnabled ? <FaVideo /> : <FaVideoSlash />}
                                            </Button>
                                            <Button style={{ marginRight: '10px' }} variant="danger" onClick={toggleAudio}>
                                                {audioEnabled ? <FaMicrophone /> : <FaMicrophoneSlash />}
                                            </Button>
                                            {isStreaming ? (
                                                !isScreenSharing ? (
                                                    <Button
                                                        style={{ marginRight: "10px" }}
                                                        variant="primary"
                                                        onClick={startScreenShare}
                                                        disabled={isScreenSharing}
                                                    >
                                                        <span className="material-symbols-outlined d-flex">present_to_all</span>
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        style={{ marginRight: "10px" }}
                                                        variant="danger"
                                                        onClick={stopScreenShare}
                                                        disabled={!isScreenSharing}
                                                    >
                                                        <span className="material-symbols-outlined d-flex">cancel_presentation</span>
                                                    </Button>
                                                )
                                            ) : ''
                                            }
                                            {!isStreaming && streamings ? (
                                                <Button style={{ marginRight: '10px' }} variant="primary" onClick={startStream}>

                                                    {isLoading ? (
                                                        <div className="spinner-border spinner-border-sm" role="status"> </div>

                                                    ) :
                                                        <FaPlay />}
                                                </Button>
                                            ) : (
                                                <Button style={{ marginRight: '10px' }} variant="danger" onClick={stopStream}>
                                                    <FaPause />
                                                </Button>
                                            )}
                                            {isStreaming ? (
                                                <Button style={{ marginRight: '10px' }} variant="danger" onClick={sendVOD}>
                                                    {isLoading ? (
                                                        <div className="spinner-border spinner-border-sm" role="status"> </div>

                                                    ) :
                                                        <FaStop />}
                                                </Button>
                                            ) : (''

                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='div-comment-content-modal card' style={{ marginLeft: '1%' }}>
                                <div className='comment-area'>
                                    {
                                        post?.id ? (
                                            <LiveChat style={{ height: '100%' }} post_id={post.id} is_live={streamings.is_live} />
                                        ) : 'Chat será habilitado ao iniciar transmissão'
                                    }
                                </div>
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>

            {/* Modal de Configurações */}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Configurações de Áudio e Vídeo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Selecione a Câmera:</Form.Label>
                            <Form.Control
                                as="select"
                                value={selectedVideoDevice}
                                onChange={(e) => setSelectedVideoDevice(e.target.value)}
                            >
                                <option value="">Escolha a câmera</option>
                                {videoDevices.map((device) => (
                                    <option key={device.deviceId} value={device.deviceId}>
                                        {device.label}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Selecione o Microfone:</Form.Label>
                            <Form.Control
                                as="select"
                                value={selectedAudioDevice}
                                onChange={(e) => setSelectedAudioDevice(e.target.value)}
                            >
                                <option value="">Escolha o microfone</option>
                                {audioDevices.map((device) => (
                                    <option key={device.deviceId} value={device.deviceId}>
                                        {device.label}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <div className="mt-3 text-center">
                            <Button variant="primary" onClick={saveSettings}>Salvar Configurações</Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal show={showExitModal} onHide={handleCancel}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmar Navegação</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Você tem certeza que deseja sair? Os conteudos de live podem ser perdidos.
                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={isLoading} variant="secondary" onClick={handleCancel}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={handleConfirmNavigation}>
                        {isLoading ? (
                            <div className="spinner-border spinner-border-sm" role="status"> </div>

                        ) : 'Sair'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default LiveBrowser;
